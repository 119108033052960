// State Management
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { produce } from 'immer';
import { Director, View } from '@millicast/sdk';

export enum DolbyBroadcastEvents {
  Active = 'active', // Fires when the live stream is, or has started broadcasting
  Inactive = 'inactive', // Fires when the stream has stopped broadcasting, but is still available.
  Stopped = 'stopped', // Fires when the stream has stopped for a given reason
}

interface DolbyLivestreamInitialState {
  dolbyView: View | null;
  isSubscribed: boolean;
  isReconnecting: boolean;
}

export interface DolbyLivestreamState extends DolbyLivestreamInitialState {
  closeDolbyStream: () => void;
  connectToDolbyStream: (
    videoRef: HTMLVideoElement,
    onInactiveStream: () => void,
  ) => void;
}

const dolbyTokenGenerator = async () => {
  const subscriberConnection = await Director.getSubscriber({
    streamName: window.DOLBY_DOCK_1_AUBIN_FARMS_STREAM_NAME,
    streamAccountId: window.DOLBY_LIVE_STREAM_ACCOUNT_ID,
    subscriberToken: window.DOLBY_DOCK_1_AUBIN_FARMS_SUBSCRIBE_TOKEN,
  });
  return subscriberConnection;
};

const initialState: DolbyLivestreamInitialState = {
  dolbyView: null,
  isSubscribed: false,
  isReconnecting: false,
};

const mutations = (setState, getState) => {
  return {
    async closeDolbyStream() {
      const dolbyView = getState().dolbyView;
      if (dolbyView) {
        dolbyView.removeAllListeners();
        await dolbyView.stop();
        setState(
          produce((state: DolbyLivestreamInitialState) => {
            state.dolbyView = null;
            state.isSubscribed = false;
            state.isReconnecting = false;
          }),
        );
      }
    },

    async connectToDolbyStream(
      videoRef: HTMLVideoElement,
      onInactiveStream: () => void,
    ) {
      let dolbyView = getState().dolbyView;
      const isSubscribed = getState().isSubscribed;

      if (dolbyView && isSubscribed) {
        return;
      }

      if (!dolbyView) {
        setState(
          produce((state: DolbyLivestreamInitialState) => {
            state.dolbyView = new View(
              window.DOLBY_DOCK_1_AUBIN_FARMS_STREAM_NAME,
              dolbyTokenGenerator,
              null,
              true /* auto reconnect */,
            );
            state.isReconnecting = false;
            state.isSubscribed = true;
          }),
        );
      }

      dolbyView = getState().dolbyView;

      await dolbyView.on('track', event => {
        if (videoRef) {
          videoRef.srcObject = event.streams[0];
          videoRef.hidden = false;
          videoRef.autoplay = true;
          setState(
            produce((state: DolbyLivestreamInitialState) => {
              state.isReconnecting = false;
            }),
          );
        }
      });

      await dolbyView.on('reconnect', () => {
        const isReconnecting = getState().isReconnecting;

        if (isReconnecting) {
          return;
        }

        setState(
          produce((state: DolbyLivestreamInitialState) => {
            state.isReconnecting = true;
          }),
        );
      });

      await dolbyView.on('broadcastEvent', event => {
        if (event.name === DolbyBroadcastEvents.Inactive) {
          onInactiveStream();
        }
      });
      try {
        await dolbyView.connect({
          events: [
            DolbyBroadcastEvents.Active,
            DolbyBroadcastEvents.Inactive,
            DolbyBroadcastEvents.Stopped,
          ],
        });
      } catch (err) {
        onInactiveStream();
      }
    },
  };
};

export const useDolbyLivestreamStore = create(combine(initialState, mutations));
