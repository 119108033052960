import {
  DroneUploadSchema,
  MannedUploadSchema,
} from '../../../MissionPlanner/components/MissionPlanningForm/FlightPlanSchema';
import { validateAdditionalMissionParams } from './UploaderValidationSchema';
import { MissionFileType } from '@/shared/types/missions.d';
import { DroneType } from '@raptormaps/raptor-flight-client-ts';

export function jsonValidation(
  jsonData: MissionFileType,
  solarFarmId?: number,
): Promise<string[]> {
  const validationSchema =
    jsonData.mission.drone === DroneType.MannedAirplane
      ? MannedUploadSchema
      : DroneUploadSchema;
  const errors = [];
  return new Promise((resolve, reject) => {
    try {
      // Combines validation from two different locations, the validationSchema that is aircraft specific and the validateAdditionalMissionParams function that checks the anything not specified in the validationSchema.
      validationSchema
        .validate(jsonData.mission, { abortEarly: false })
        .catch(validationError => {
          validationError.inner.forEach(error => {
            errors.push([
              error.path,
              `Error at ${error.path}: "${error.params.originalValue}". ${error.message}`,
            ]);
          });
        });

      const isInvalid = validateAdditionalMissionParams(jsonData, solarFarmId);
      if (isInvalid) {
        isInvalid.forEach((error, index) => {
          errors.push([index, error]);
        });
      }
      resolve(errors);
    } catch (error) {
      reject(error);
    }
  });
}
