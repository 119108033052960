import { useQuery } from 'react-query';
import {
  DolbyLivestreamState,
  useDolbyLivestreamStore,
} from '../stores/DolbyLivestreamStore';

const useDolby = () => {
  const connectToDolbyStream = useDolbyLivestreamStore(
    (state: DolbyLivestreamState) => state.connectToDolbyStream,
  );

  const useSubscribeToDolbyStream = (
    videoRef: HTMLVideoElement,
    enabled,
    onError: () => void,
  ) => {
    return useQuery({
      queryKey: 'useSubscribeToDolbyStream',
      queryFn: async () => {
        return await connectToDolbyStream(videoRef, onError);
      },
      enabled,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError,
    });
  };

  return {
    useSubscribeToDolbyStream,
  };
};

export default useDolby;
