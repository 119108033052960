// Components
import DeviceCard from './DeviceCard';

// Styled Components
import {
  Divider,
  SiteOverviewContainer,
  CollapsibleTopPadding,
} from '../Overview.styled';
import { Text } from '@raptormaps/text';
import { SectionCollapsible } from '../../MissionPlanner/components/MissionPlanningForm/MissionPlanningSidebar/components/MissionParameters/MissionParametersSection.styles';

// Types
import {
  DeviceResponse,
  DockDeviceIdsResponse,
} from '@raptormaps/raptor-flight-client-ts';
import { DockDevice } from '@/shared/types/devices.d';
import { Feature, Point } from '@turf/helpers';

interface SiteOverviewProps {
  navigateToDock: (
    dockDevice: DockDevice,
    dockLocation: Feature<Point>,
  ) => void;
  devices: DeviceResponse[];
  dockDeviceIds: DockDeviceIdsResponse[];
}

const SiteOverview = ({
  navigateToDock,
  devices,
  dockDeviceIds,
}: SiteOverviewProps) => {
  const handleRenderDeviceCards = (
    devices: DeviceResponse[],
    dockDeviceIds: DockDeviceIdsResponse[],
  ) => {
    if (!dockDeviceIds?.length) {
      return (
        <Text variant="paragraph_medium_bold" color="grey_300">
          No devices found for this site
        </Text>
      );
    }
    const dockDevices: DockDevice[] = dockDeviceIds.map(
      (dockIdDeviceIds: DockDeviceIdsResponse) => {
        const dock = devices.find(
          (device: DeviceResponse) => device.id === dockIdDeviceIds.dockId,
        );

        const dockDevices = dockIdDeviceIds.deviceIds.map(
          (deviceId: number) => {
            return devices.find(
              (device: DeviceResponse) => device.id === deviceId,
            );
          },
        );

        return { ...dock, devices: dockDevices };
      },
    );

    return dockDevices.map((dockDevice: DockDevice) => (
      <DeviceCard
        dockDevice={dockDevice}
        key={dockDevice.id}
        navigateToDockOverview={navigateToDock}
      />
    ));
  };

  return (
    <>
      <Divider />
      <SiteOverviewContainer>
        <CollapsibleTopPadding />
        <SectionCollapsible
          title="Online Devices"
          variant="ghost"
          {...{ defaultOpen: true }}
        >
          {handleRenderDeviceCards(devices, dockDeviceIds)}
        </SectionCollapsible>
      </SiteOverviewContainer>
    </>
  );
};

export default SiteOverview;
