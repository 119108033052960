import { FlightModeType } from '@raptormaps/raptor-flight-client-ts';

export const TemporaryFlightModeType = {
  SquareOrbital: 'square_orbital',
} as const;

export type TemporaryFlightModeType =
  (typeof TemporaryFlightModeType)[keyof typeof TemporaryFlightModeType];

export type CombinedFlightModeType = FlightModeType | TemporaryFlightModeType;
