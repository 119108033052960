// Styled components
import styled from 'styled-components';

// Raptor UI components
import { Button } from '@raptormaps/button';
import { Stack, Row } from '@raptormaps/layout';
import { TextInput } from '@raptormaps/text-input';
import { Text } from '@raptormaps/text';
import { Collapsible } from '@raptormaps/collapsible';
import theme from '@raptormaps/theme';

export const MissionParametersContainer = styled(Stack)`
  margin-top: 32px;
  width: 100%;
  display: flex;
  gap: 1em;
  padding: 0px 10px;
`;

export const DeviceDropdownButton = styled(Button)`
  width: 100%;
  justify-content: space-between;
`;

// this is a quarter width text input using FLEX
export const QuarterWidthTextInputFlex = styled(TextInput)`
  > label {
    margin-top: -20px;
    text-align: left;
  }

  > input {
    width: 50%;
  }
`;

// using flex to put it 2/3s of the way down a row
export const InlineQuartWidthTextInput = styled(QuarterWidthTextInputFlex)`
  > input {
    margin-right: 38px;
    width: 20%;
  }
`;

// This is a quarter quarter width text input using GRID
export const QuarterWidthTextInput = styled(TextInput)`
  grid-column: ${props => props.gridColumn} / span 1;
  grid-row: ${props => props.gridRow} / span 1;
  width: 80%;
  height: 16px;
  position: relative;

  > label {
    width: 1vw;
    letter-spacing: 0.5px;
    height: 28px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: -3px;
  }

  input {
    border-color: ${theme.colors.neutral_300};
  }

  span {
    color: ${theme.colors.error_400};
    overflow: visible;
    white-space: nowrap;
    position: absolute;
    top: 50px;
    ${props => (props.helperTextStyle ? props.helperTextStyle : null)}
  }
`;

export const FullWidthGridSection = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 10px;
  justify-items: normal;
  text-align: left;
`;

export const GridColumnOneSpanFour = styled.div`
  grid-column: 1 / span 4;
`;

export const GridColumnOneSpanTwo = styled.div`
  grid-column: 1 / span 2;
`;

export const FormLabel = styled(Text)`
  letter-spacing: 0.5px;
  white-space: nowrap;
  display: flex;
  gap: 1em;
  overflow: ${props => (props.overflow ? props.overflow : 'hidden')};
  text-overflow: ellipsis;
`;

export const MinMax = styled.div`
  grid-column: ${props => props.gridColumn} / span 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${props => (props.marginTop ? props.marginTop : '18px')};

  > span {
    height: 15px;
    color: ${theme.colors.neutral_600};
  }
`;

export const InlineIconToggle = styled.div`
  ${props =>
    props.disabled
      ? `
  * {
    cursor: not-allowed;
  }

  > svg {
    color: ${theme.colors.grey_200};
  }

  > span {
    color: ${theme.colors.grey_200};
  }
  `
      : ``}

  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    align-items: flex-end;
    gap: 10px;
  }
`;

export const ParagraphWithTabs = styled.div`
  width: 100%;
  > span {
    margin-bottom: 5px;
  }

  > div {
    width: 100%;
  }
`;

export const FullWidthTextAlignLeftDiv = styled.div`
  width: 100%;
  text-align: left;
`;

export const SectionCollapsible = styled(Collapsible)`
  width: calc(100% + 25px);
  margin-left: -10px;
  padding-left: 10px;

  > button {
    width: 105%;
    margin-left: -10px;
    padding-left: 30px;
    padding-right: calc(30px + 1vw);
  }

  > div {
    margin-left: -10px;
    gap: 10px;
  }

  > button > span {
    text-align: left;
    margin-left: -10px;
  }
`;

export const FullRowTabs = styled.div`
  > div {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const GridTextWithElipsis = styled(Text)`
  grid-column: ${props => props.gridColumn} / span ${props => props.gridSpan};
  max-height: 40px;
  padding-top: 18px;
  margin-left: 1vw;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // Number of lines you want to display
  -webkit-box-orient: vertical;
`;

export const FullWidthRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
  aligt-items: center;
`;

export const FieldErrorText = styled(Text)`
  color: ${theme.colors.error_400};
  margin-top: -15px;
  text-align: left;
  line-height: 20px;
`;
