// External Dependencies
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import dayjs from 'dayjs';
import {
  MissionResponse,
  FlightUploadResponse,
} from '@raptormaps/raptor-flight-client-ts';
import { FeatureCollection, Polygon, Feature } from '@turf/helpers';
// RaptorUI
import { Text } from '@raptormaps/text';
import { Button } from '@raptormaps/button';
import { Sidebar } from '@raptormaps/sidebar';
import { useToast } from '@raptormaps/toast';
import { Row, Stack } from '@raptormaps/layout';
// Internal Dependencies
// Components
import DigitalTwinSection from '@/shared/components/DigitalTwin/DigitalTwin';
import BackgroundSection from '@/shared/components/Background/Background';
import { Divider, SideBarContent } from '@/shared/styles/sidebar.styles';
import {
  MissionTaskFloatingSidebarContainer,
  MissionTaskCollapsableContainer,
} from '../CreateMissionTask/CreateMissionTask.styles';
import { useCreateKmzUpload } from '@/shared/hooks/useFlights';
import { FullWidthStack } from '@/shared/styles/sidebar.styles';
import { ComponentLoader } from '@/shared/components/ComponentLoader/ComponentLoader';
import { SidebarLoader } from '@/shared/components/SidebarLoader/SidebarLoader';
import { DockDeviceCard } from '@/shared/components/DockDeviceCard/DockDeviceCard';
import { DeviceIncompatibleError } from '../CreateMissionTask/components/DeviceIncompatibleError';
import { MissionTaskCard } from './components/MissionTaskCard';
import { MissionInfo } from './components/MissionInfo';

// hooks
import { useDockAndDevices } from '@/shared/hooks/useDockDevices';
import { useUrlParams } from '@/shared/hooks/useUrlParams';
import { useGetMissionTask } from '@/shared/hooks/useMissionTasks';

//utils
import { createKmzForUpload } from './FlightPlanner.utils';
import { parseFlightApiErrorDetails } from '@/shared/utils/utils';

// types / constants
import { DEFAULT_INTERVALOMETER } from '@/pages/MissionPlanner/missionConstants';
import { DEVICE_MODEL_NAME_TO_DRONE_TYPE } from '../../missionTaskFormConstants';
import { IntervalometerObject } from '@/shared/types/missions.d';
import { ERROR_TOAST_DURATION } from '@/shared/constants/appConstants';

interface FlightPlannerProps {
  solarFarmId: number;
  activeMission: MissionResponse;
  isLoadingMission: boolean;
  map: mapboxgl.Map;
  polygon: Feature<Polygon>;
  waypoints: FeatureCollection;
  activeRowId: number;
  dockId: number;
}

export const FlightPlanner = ({
  solarFarmId,
  activeMission,
  isLoadingMission,
  map,
  polygon,
  waypoints,
  activeRowId,
  dockId,
}: FlightPlannerProps) => {
  const toast = useToast();
  const { activeDockDevice, isLoading: isLoadingDevices } = useDockAndDevices({
    solarFarmId: solarFarmId,
    dockId,
  });

  const missionTaskId = useUrlParams<number>('missionTaskId', 'number');
  const handleMissionTaskError = () =>
    toast.error(`Error fetching mission task with ID ${missionTaskId}`, {
      duration: ERROR_TOAST_DURATION,
      dismissable: true,
    });

  const { data: missionTask, isLoading } = useGetMissionTask(
    missionTaskId,
    solarFarmId,
    handleMissionTaskError,
  );

  const { mutateAsync: createKmzUpload, isLoading: isUploadingKMZ } =
    useCreateKmzUpload(solarFarmId);
  const [kmzResponse, setKmzResponse] = useState<FlightUploadResponse>(null);
  const [slider, setSlider] = useState<number>(25);
  const [intervalometer, setIntervalometer] = useState<IntervalometerObject>(
    DEFAULT_INTERVALOMETER,
  );
  const navigate = useNavigate();

  const checkInvalidDrone =
    activeMission &&
    activeDockDevice &&
    activeMission.drone !=
      DEVICE_MODEL_NAME_TO_DRONE_TYPE[
        activeDockDevice?.dockDroneModel?.modelName
      ];

  const uploadKmz = async () => {
    const continuousOperationStartTime = dayjs
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss');
    const kmz = await createKmzForUpload({
      map,
      mission: activeMission,
      continuousOperationStartTime,
      polygon,
      waypoints,
      intervalometer,
      toast,
    });

    if (kmz)
      try {
        return await createKmzUpload(kmz, {
          onSuccess: result => {
            toast.success('Flight Sucessfully Uploaded', {
              duration: 10000,
            });
            setKmzResponse(result);
          },
        });
      } catch (err) {
        const apiError = await parseFlightApiErrorDetails(
          err,
          'Error uploading KMZ. Please try again.',
        );
        const message = apiError[0]?.msg || apiError;
        toast.error(message, {
          duration: 10000,
        });
      }
  };

  const handleRenderLoadingOrSuccess = () => {
    if (isUploadingKMZ) {
      return <SidebarLoader message="Uploading KMZ..." />;
    } else if (kmzResponse) {
      return (
        <Stack style={{ padding: '10px' }}>
          <Text align="left" variant="paragraph_medium">
            KMZ Uploaded:
          </Text>
          <Text align="left" variant="paragraph_large_bold">
            {kmzResponse.fileUrl}
          </Text>
          <Text align="left" variant="paragraph_large_bold">
            {kmzResponse.md5Signature}
          </Text>
        </Stack>
      );
    } else return null;
  };

  return (
    <>
      <MissionTaskFloatingSidebarContainer>
        <Sidebar title={'Flight'} defaultOpen={true}>
          {handleRenderLoadingOrSuccess() || (
            <>
              <SideBarContent align="start">
                <Button
                  icon="ArrowLeft"
                  iconPosition="left"
                  size="small"
                  variant="tertiary"
                  role="link"
                  style={{ fontWeight: '600', marginLeft: '-14px' }}
                  onClick={() =>
                    navigate({
                      pathname: '/tasks',
                      search: `solar_farm_id=${solarFarmId}`,
                    })
                  }
                >
                  Back
                </Button>
                <Text variant="paragraph_small" align="left">
                  Mission Task
                </Text>
                <MissionTaskCard
                  isLoading={isLoading}
                  missionTask={missionTask}
                />
                <Divider />

                <Stack style={{ width: '100%' }}>
                  <MissionInfo
                    activeMission={activeMission}
                    isLoadingMission={isLoadingMission}
                    intervalometer={intervalometer}
                    setIntervalometer={setIntervalometer}
                  />
                  <Row align="center" justify="space-between">
                    <Text variant="paragraph_small" align="left">
                      Device
                    </Text>
                  </Row>
                  {isLoadingDevices ? (
                    <ComponentLoader message={'Loading Device...'} />
                  ) : (
                    <>
                      {checkInvalidDrone && <DeviceIncompatibleError />}
                      {activeDockDevice && (
                        <DockDeviceCard
                          dockDevice={activeDockDevice}
                          isSelected={activeDockDevice}
                        />
                      )}
                    </>
                  )}
                </Stack>
              </SideBarContent>
              <MissionTaskCollapsableContainer>
                <FullWidthStack style={{ width: '100%' }}>
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      navigate({
                        pathname: '/tasks',
                        search: `solar_farm_id=${solarFarmId}`,
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    disabled={
                      checkInvalidDrone || !map || !polygon || !waypoints
                    }
                    onClick={uploadKmz}
                  >
                    Save Flight
                  </Button>
                </FullWidthStack>
                <DigitalTwinSection
                  solarFarmId={solarFarmId}
                  map={map}
                  activeRowId={activeRowId}
                />
                <BackgroundSection
                  map={map}
                  solarFarmId={solarFarmId}
                  slider={slider}
                  setSlider={setSlider}
                  useDroneIcon={false}
                />
              </MissionTaskCollapsableContainer>
            </>
          )}
        </Sidebar>
      </MissionTaskFloatingSidebarContainer>
    </>
  );
};
