import { DroneType } from '@raptormaps/raptor-flight-client-ts';

interface DJIDroneConfiguration {
  droneEnumValue: number;
  payloadEnumValue: number;
  finishAction: string;
  imageFormat: string;
}
export const DJI_DRONE_VALUES: { [key in DroneType]?: DJIDroneConfiguration } =
  {
    [DroneType.M3t]: {
      droneEnumValue: 77,
      payloadEnumValue: 67,
      finishAction: 'noAction',
      imageFormat: 'wide,ir',
    },
    [DroneType.M30t]: {
      droneEnumValue: 67,
      payloadEnumValue: 53,
      finishAction: 'noAction',
      imageFormat: 'wide,ir',
    },
    [DroneType.M30tDock]: {
      droneEnumValue: 67,
      payloadEnumValue: 53,
      finishAction: 'goHome',
      imageFormat: 'wide,ir',
    },
    [DroneType.M3tdDock]: {
      droneEnumValue: 91,
      payloadEnumValue: 81,
      finishAction: 'goHome',
      imageFormat: 'visable,ir',
    },
  };
