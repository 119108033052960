import { useEffect, useState, useRef } from 'react';

// Raptor UI
import { Text } from '@raptormaps/text';
import { useToast } from '@raptormaps/toast';
import { Card } from '@raptormaps/card';
import { Icon } from '@raptormaps/icon';
import { IconButton } from '@raptormaps/button';
import { Stack, Row } from '@raptormaps/layout';

// Styles
import {
  LiveSreamViewerModalContainer,
  ViewerBackgroundContainer,
  VIDEO_WIDTH,
  VIDEO_HEIGHT,
} from './DockOverview.styled';

// Draggable
import Draggable from 'react-draggable';

// Live Stream Store
import {
  DolbyLivestreamState,
  useDolbyLivestreamStore,
} from '@/shared/stores/DolbyLivestreamStore';

// Constants
import { ERROR_TOAST_DURATION } from '@/shared/constants/appConstants';

const LiveStreamViewerModal = () => {
  const [isMinimized, setIsMinimized] = useState(false);

  const minimizeView = () => setIsMinimized(true);
  const maximizeView = () => setIsMinimized(false);

  const isSubscribed = useDolbyLivestreamStore(
    (state: DolbyLivestreamState) => state.isSubscribed,
  );

  const openDolbyStream = useDolbyLivestreamStore(
    (state: DolbyLivestreamState) => state.connectToDolbyStream,
  );

  const videoRef = useRef(null);
  const toast = useToast();

  const handleStreamConnectionError = () => {
    toast.error(
      'Failed to connect to live stream. Stream will continue attempting to reconnect in the background.',
      {
        duration: ERROR_TOAST_DURATION,
        dismissable: true,
      },
    );
  };

  useEffect(() => {
    if (videoRef.current && !isSubscribed) {
      openDolbyStream(videoRef.current, handleStreamConnectionError);
    }
  }, [videoRef, isSubscribed]);

  return (
    <Draggable bounds="parent">
      <LiveSreamViewerModalContainer>
        <Card style={{ padding: 0 }}>
          <Stack gutter="xxs">
            <Row
              justify="space-between"
              align="center"
              gutter="xxs"
              style={{
                width: '100%',
                padding: '12px 12px 0 12px',
              }}
            >
              <Row justify="space-between" align="center">
                <Icon icon="CameraRetro" size="large" />
                <Text> RGB </Text>
                <Icon icon="HighDefinition" size="large" />
                <Text> 1080p </Text>
              </Row>
              <Row gutter="xxs" justify="space-between" align="center">
                {isMinimized ? (
                  <IconButton
                    aria-label="maximize-live-stream-modal-icon"
                    icon="Enlarge"
                    size="large"
                    variant="tertiary"
                    onClick={() => maximizeView()}
                  />
                ) : (
                  <IconButton
                    aria-label="minimize-live-stream-modal-icon"
                    icon="WindowMinimize"
                    size="large"
                    variant="tertiary"
                    onClick={() => minimizeView()}
                  />
                )}
              </Row>
            </Row>
            <ViewerBackgroundContainer>
              <video
                autoPlay
                playsInline
                width={VIDEO_WIDTH}
                height={VIDEO_HEIGHT}
                controls
                style={{ display: isMinimized ? 'none' : 'block' }}
                muted
                ref={videoRef}
              />
            </ViewerBackgroundContainer>
          </Stack>
        </Card>
      </LiveSreamViewerModalContainer>
    </Draggable>
  );
};

export default LiveStreamViewerModal;
