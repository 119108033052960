// External Dependencies
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import {
  FarmObjectsApi,
  ObjectType,
} from '@raptormaps/customer-farm-api-client-ts';
import { FeatureCollection, Geometries } from '@turf/helpers';
import { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { useToast } from '@raptormaps/toast';

// Internal Dependencies
import { useAppContext } from '../context/AppContext';
import { useApiLegacy } from './useApiLegacy';
import * as mapboxFarms from '../components/Map/farms';
import { SolarFarmResponse } from '@raptormaps/raptor-flight-client-ts';
import { updateEquipmentSource } from '../components/Map/sources';
import { INVERTER_BLOCK_SOURCE_ID } from '../components/Map/sources/InverterBlockSources';
import { INVERTER_SOURCE_ID } from '../components/Map/sources/InverterSources';
import { COMBINER_SOURCE_ID } from '../components/Map/sources/CombinerSources';
import { PYRANOMETER_SOURCE_ID } from '../components/Map/sources/PyranometerSources';
import { MODULE_SOURCE_ID } from '../components/Map/sources/ModuleSources';
import {
  GEOSPATIAL_ROW_SOURCE_ID,
  GEOSPATIAL_ROW_LABEL_SOURCE_ID,
} from '../components/Map/sources/GeospatialRowSources';
import { GeoJSONPolygonToPoint } from '../components/Map/utils';
import { STRING_SOURCE_ID } from '../components/Map/sources/StringSources';

export const useGetInverterBlocks = (solarFarmId: number) => {
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useAppContext();
  const orgId = user.latest_org_id;

  return useQuery({
    queryKey: ['inverterBlocks', orgId, solarFarmId],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${window.REACT_APP_FARMBUILDER_API_ENDPOINT}/solar_farms/${solarFarmId}/row_bounding_boxes?org_id=${orgId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: '/',
          },
        },
      );

      if (response.status !== 200) {
        throw new Error('Failed to fetch inverter blocks');
      }
      return response.json();
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetEquipmentRows = (solarFarmId: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const FarmsObjectApi = useApiLegacy(FarmObjectsApi, {
    basePath: window.REACT_APP_FARMBUILDER_API_ENDPOINT,
  });
  return useQuery({
    queryKey: ['rows', orgId, solarFarmId],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      const response = await FarmsObjectApi.getFarmObjectGeojson({
        solarFarmId: solarFarmId,
        orgId: orgId,
        objectType: ObjectType.Row,
      });
      return response as FeatureCollection<Geometries>;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetEquipmentCombiners = (solarFarmId: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const FarmsObjectApi = useApiLegacy(FarmObjectsApi, {
    basePath: window.REACT_APP_FARMBUILDER_API_ENDPOINT,
  });
  return useQuery({
    queryKey: ['combiners', orgId, solarFarmId],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      const response = await FarmsObjectApi.getFarmObjectGeojson({
        solarFarmId: solarFarmId,
        orgId: orgId,
        objectType: ObjectType.Combiner,
      });
      return response as FeatureCollection<Geometries>;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetEquipmentInverters = (solarFarmId: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const FarmsObjectApi = useApiLegacy(FarmObjectsApi, {
    basePath: window.REACT_APP_FARMBUILDER_API_ENDPOINT,
  });
  return useQuery({
    queryKey: ['inverters', orgId, solarFarmId],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      const response = await FarmsObjectApi.getFarmObjectGeojson({
        solarFarmId: solarFarmId,
        orgId: orgId,
        objectType: ObjectType.Inverter,
      });
      return response as FeatureCollection<Geometries>;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetEquipmentPyranometers = (solarFarmId: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const FarmsObjectApi = useApiLegacy(FarmObjectsApi, {
    basePath: window.REACT_APP_FARMBUILDER_API_ENDPOINT,
  });
  return useQuery({
    queryKey: ['pyranometers', orgId, solarFarmId],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      const response = await FarmsObjectApi.getFarmObjectGeojson({
        solarFarmId: solarFarmId,
        orgId: orgId,
        objectType: ObjectType.Pyranometer,
      });
      return response as FeatureCollection<Geometries>;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetEquipmentModulesAndStrings = (
  solarFarmId: number,
  rowId: number,
) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const FarmsObjectApi = useApiLegacy(FarmObjectsApi, {
    basePath: window.REACT_APP_FARMBUILDER_API_ENDPOINT,
  });

  return useQuery({
    queryKey: ['modules', rowId],
    queryFn: async () => {
      if (!orgId || !solarFarmId || !rowId) return null;
      const response = await FarmsObjectApi.getGeospatialRowChildGeojson({
        solarFarmId: solarFarmId,
        rowId: rowId,
      });
      return {
        modules: response.modules as FeatureCollection<Geometries>,
        strings: response.strings as FeatureCollection<Geometries>,
      };
    },
  });
};

export const useEquipmentSourcesOnMap = (
  map: mapboxgl.Map,
  solarFarm: SolarFarmResponse,
  rowId?: number,
) => {
  const toast = useToast();
  // there is a tile set don't fetch equipment
  const equipmentSourcesSolarFarmId = mapboxFarms?.getSolarFarmById[
    solarFarm?.id
  ]
    ? null
    : solarFarm?.id;

  const { data: inverterBlocks, error: inverterBlocksError } =
    useGetInverterBlocks(equipmentSourcesSolarFarmId);

  const { data: geospatialRows, error: rowsError } = useGetEquipmentRows(
    equipmentSourcesSolarFarmId,
  );

  const { data: inverters, error: inverterError } = useGetEquipmentInverters(
    equipmentSourcesSolarFarmId,
  );

  const { data: combiners, error: combinerError } = useGetEquipmentCombiners(
    equipmentSourcesSolarFarmId,
  );

  const { data: pyranometers, error: pyranometerError } =
    useGetEquipmentPyranometers(equipmentSourcesSolarFarmId);

  const { data: modulesAndStrings, error: moduleAndStringError } =
    useGetEquipmentModulesAndStrings(equipmentSourcesSolarFarmId, rowId);

  useEffect(() => {
    if (!map) return;

    updateEquipmentSource(map, INVERTER_BLOCK_SOURCE_ID, inverterBlocks);

    if (inverterBlocksError) {
      toast.error(`Error fetching inverter blocks ${solarFarm?.name}`, {
        duration: 5000,
      });
    }
  }, [inverterBlocks, inverterBlocksError, map]);

  useEffect(() => {
    if (!map) return;

    const geospatialRowLabels = GeoJSONPolygonToPoint(geospatialRows);
    // add rows
    updateEquipmentSource(map, GEOSPATIAL_ROW_SOURCE_ID, geospatialRows);
    // add row labels
    updateEquipmentSource(
      map,
      GEOSPATIAL_ROW_LABEL_SOURCE_ID,
      geospatialRowLabels as FeatureCollection<Geometries>,
    );
    if (rowsError) {
      toast.error(`Error fetching geospatial rows for ${solarFarm?.name}`, {
        duration: 5000,
      });
    }
  }, [geospatialRows, rowsError, map]);

  useEffect(() => {
    if (!map) return;

    updateEquipmentSource(map, INVERTER_SOURCE_ID, inverters);
    if (inverterError) {
      toast.error(`Error inverters for ${solarFarm?.name}`, { duration: 5000 });
    }
  }, [inverters, inverterError, map]);

  useEffect(() => {
    if (!map) return;

    updateEquipmentSource(map, COMBINER_SOURCE_ID, combiners);
    if (combinerError) {
      toast.error(`Error fetching combiners for ${solarFarm?.name}`, {
        duration: 5000,
      });
    }
  }, [combiners, combinerError, map]);

  useEffect(() => {
    if (!map) return;

    updateEquipmentSource(map, PYRANOMETER_SOURCE_ID, pyranometers);
    if (pyranometerError) {
      toast.error(`Error fetching pyranometers for ${solarFarm?.name}`, {
        duration: 5000,
      });
    }
  }, [pyranometers, pyranometerError, map]);

  useEffect(() => {
    if (!map) return;

    updateEquipmentSource(map, MODULE_SOURCE_ID, modulesAndStrings?.modules);
    updateEquipmentSource(map, STRING_SOURCE_ID, modulesAndStrings?.strings);
    if (moduleAndStringError) {
      toast.error(`Error fetching modules and strings for ${solarFarm?.name}`, {
        duration: 5000,
      });
    }
  }, [modulesAndStrings, moduleAndStringError, rowId, map]);
};
