// external Dependancies
import { Feature } from '@turf/helpers';
import { FormikProps } from 'formik';
// RaptorUI
import { Row, Stack } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';

// Utilities
import {
  calculateFlightTimeFromGridline,
  getFieldOfViewSpacing,
} from '../../../../utils/utils';

// Styled Components
import { SectionCollapsible } from './MissionParameters/MissionParametersSection.styles';

// Types
import { FlightPlanningFormikValues } from '@/shared/types/missions.d';
import { CombinedFlightModeType } from '@/shared/types/tempMissions';

interface MissionStatsSectionProps {
  formik: FormikProps<FlightPlanningFormikValues>;
  flightPathLine: Feature;
  flightMode: CombinedFlightModeType;
}

const MissionStatsSection = ({
  formik,
  flightPathLine,
  flightMode,
}: MissionStatsSectionProps) => {
  const { values } = formik;

  return (
    <SectionCollapsible
      iconColor="neutral_900"
      title="Mission Stats"
      variant="ghost"
    >
      <Stack justify="start">
        <Row justify="space-between">
          <Text variant="paragraph_medium">
            Data Collection Time (minutes){' '}
          </Text>
          <Text>
            {(
              calculateFlightTimeFromGridline(
                flightPathLine,
                values.flightSpeed,
                flightMode,
              ) / 60
            ).toFixed(1)}
          </Text>
        </Row>
        <Row justify="space-between">
          <Text variant="paragraph_medium">Side Spacing (meters) </Text>
          <Text>
            {getFieldOfViewSpacing(
              values.altitude,
              values.fieldOfViewVertical,
              values.sideOverlap,
            ).toFixed(1)}
          </Text>
        </Row>
        <Row justify="space-between">
          <Text variant="paragraph_medium">Front Spacing (meters) </Text>
          <Text>
            {getFieldOfViewSpacing(
              values.altitude,
              values.fieldOfViewHorizontal,
              values.frontOverlap,
            ).toFixed(1)}
          </Text>
        </Row>
      </Stack>
    </SectionCollapsible>
  );
};

export default MissionStatsSection;
