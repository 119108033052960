// RaptorUI
import { Checkbox } from '@raptormaps/checkbox';
import { CheckboxVariant } from '@raptormaps/checkbox/build/Checkbox.interface';
import { Icon } from '@raptormaps/icon';
import { Text } from '@raptormaps/text';
import { InlineIconToggle } from '../../../pages/MissionPlanner/components/MissionPlanningForm/MissionPlanningSidebar/components/MissionParameters/MissionParametersSection.styles';
import { Tooltip } from '@raptormaps/tooltip';
import { Stack } from '@raptormaps/layout';
import theme from '@raptormaps/theme';

// Types
import { IntervalometerObject } from '@/shared/types/missions';

// CSS
import './WaypointReduction.css';

interface WaypointReductionSectionProps {
  intervalometer: IntervalometerObject;
  setIntervalometer: React.Dispatch<React.SetStateAction<IntervalometerObject>>;
}
const WaypointReductionSection = ({
  intervalometer,
  setIntervalometer,
}: WaypointReductionSectionProps) => {
  return (
    <>
      <InlineIconToggle>
        <Text variant={'paragraph_medium_bold'}>
          <Icon icon="CameraRotate" size={'large'} />
          Intervalometer
          <Tooltip
            colorVariant="dark"
            delay={100}
            placement={{
              align: 'center',
              side: 'right',
            }}
            tip="Intervalometer changes the camera trigger from “at each waypoint” to “at a set distance”."
            shouldWrapChildren={true}
          >
            <Icon
              aria-label="Altitude Offset Information"
              icon="CircleInfo"
              size={'medium'}
            />
          </Tooltip>
        </Text>
        <Checkbox
          name="intervalometerBool"
          checked={intervalometer.intervalometerBool}
          onChange={e => {
            const intervalometerBool = e.target.checked;
            if (intervalometerBool) {
              setIntervalometer({
                ...intervalometer,
                intervalometerBool: true,
              });
            } else {
              setIntervalometer({
                ...intervalometer,
                intervalometerBool: false,
                waypointReductionBool: false,
              });
            }
          }}
          variant={CheckboxVariant.TOGGLE}
        />
      </InlineIconToggle>

      <InlineIconToggle disabled={!intervalometer.intervalometerBool}>
        <Text variant={'paragraph_medium_bold'}>
          <Icon icon="RectangleTerminal" size={'large'} />
          Waypoint Optimization
          <Tooltip
            colorVariant="dark"
            delay={100}
            placement={{
              align: 'center',
              side: 'right',
            }}
            tip={
              <Stack gutter={'none'}>
                <Text variant={'paragraph_small'} color={theme.colors.white}>
                  Waypoint optimization reduces the total amount of waypoints
                  exported when downloading a mission.
                </Text>
                <Text variant={'paragraph_small'} color={theme.colors.white}>
                  ATTN (1): Requires Intervalometer to be enabled.
                </Text>
                <Text variant={'paragraph_small'} color={theme.colors.white}>
                  ATTN (2): This feature may reduce the precision of terrain
                  follow.
                </Text>
              </Stack>
            }
            shouldWrapChildren={true}
          >
            <Icon
              aria-label="Altitude Offset Information"
              icon="CircleInfo"
              size={'medium'}
            />
          </Tooltip>
        </Text>
        <Checkbox
          name="waypointReductionBool"
          disabled={!intervalometer.intervalometerBool}
          checked={intervalometer.waypointReductionBool}
          onChange={e => {
            setIntervalometer({
              ...intervalometer,
              waypointReductionBool: e.target.checked,
            });
          }}
          variant={CheckboxVariant.TOGGLE}
        />
      </InlineIconToggle>
    </>
  );
};

export default WaypointReductionSection;
