// React
import { useState } from 'react';

// Styled Components
import { Tabs } from '@raptormaps/tabs';
import { DropdownMenu, DropdownOption } from '@raptormaps/dropdown-menu';
import {
  FormLabel,
  GridColumnOneSpanFour,
  GridColumnOneSpanTwo,
  FullRowTabs,
} from '../../MissionParametersSection.styles';
import { DropdownButton } from '@/shared/styles/input.styles';

// Types
import { FlightModeType } from '@raptormaps/raptor-flight-client-ts';
import { DataCollectionType } from '@/shared/types/missions';

// Constants
import { DATA_COLLECTION_OPTIONS } from '@/pages/MissionPlanner/missionConstants';

import { CombinedFlightModeType } from '@/shared/types/tempMissions';
import {
  FLIGHT_MODE_TO_DISPLAY_NAME,
  DATA_COLLECTION_TYPE_TO_INSPECTION_TYPE,
} from '@/shared/constants/missionLookups';

interface DataCollctionProps {
  flightModeOptions: string[];
  flightMode: CombinedFlightModeType;
  handleClick: (mode: FlightModeType) => void;
}

const DataCollection = ({
  flightMode,
  flightModeOptions,
  handleClick,
}: DataCollctionProps) => {
  const [dataCollectionType, setDataCollectionType] =
    useState<DataCollectionType>('Area');

  const filteredFlightModes = () => {
    const validFlightModes =
      DATA_COLLECTION_TYPE_TO_INSPECTION_TYPE[dataCollectionType];
    return flightModeOptions.filter((flightMode: FlightModeType) =>
      validFlightModes.includes(flightMode),
    );
  };

  const flightModeTypeOptions: DropdownOption[] = filteredFlightModes().map(
    flightMode => ({
      onSelect: () => handleClick(flightMode as FlightModeType),
      value: FLIGHT_MODE_TO_DISPLAY_NAME[flightMode],
      variant: 'option',
      style: { fontSize: '14px', justifyContent: 'start' },
    }),
  );

  return (
    <>
      <GridColumnOneSpanFour>
        {flightMode !== FlightModeType.Airplane && (
          <>
            <FormLabel variant="label_small">Data Collection Type</FormLabel>
            <FullRowTabs>
              <Tabs
                handleClick={(e: number) => {
                  const dataCollectionType: DataCollectionType =
                    DATA_COLLECTION_OPTIONS[e];

                  setDataCollectionType(dataCollectionType);
                  handleClick(
                    DATA_COLLECTION_TYPE_TO_INSPECTION_TYPE[
                      dataCollectionType
                    ][0] as FlightModeType,
                  );
                }}
                items={[...DATA_COLLECTION_OPTIONS]}
                activeIndex={DATA_COLLECTION_OPTIONS.indexOf(
                  dataCollectionType,
                )}
              />
            </FullRowTabs>
          </>
        )}
      </GridColumnOneSpanFour>
      <GridColumnOneSpanTwo>
        <FormLabel variant="label_small">Inspection Type</FormLabel>
        <DropdownMenu
          menuContentProps={{
            align: 'start',
            sideOffset: 0,
            side: 'bottom',
            style: {
              maxHeight: '70vh',
              width: '219px',
              minWidth: '120px',
              marginTop: 0,
            },
          }}
          items={flightModeTypeOptions}
          modal={false}
        >
          <DropdownButton
            disabled={flightMode === FlightModeType.Airplane}
            size="small"
            variant="secondary"
            id="inspection-type-dropdown-trigger"
            data-testid="inspection-type-dropdown-trigger"
          >
            {FLIGHT_MODE_TO_DISPLAY_NAME[flightMode]}
          </DropdownButton>
        </DropdownMenu>
      </GridColumnOneSpanTwo>
    </>
  );
};

export default DataCollection;
