//external dependancies
import { useQuery, useQueryClient, useMutation } from 'react-query';
import {
  MissionTasksApi,
  ResponseError,
  MissionTasksOverviewWithTotalResponse,
  MissionTaskOverviewResponse,
  MissionTaskInput,
} from '@raptormaps/raptor-flight-client-ts';

// local dependancies
import { useApi } from './useApi';
import { useAppContext } from '../context/AppContext';

export const GET_MISSION_TASKS_PAGINATION_LIMIT = 20;

export const useGetMissionTasks = (
  solarFarmId?: number,
  nameSearchTerm = '',
  limit?: number,
  offset?: number,
) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(MissionTasksApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  const query = useQuery({
    queryKey: [
      'mission_tasks_with_total',
      orgId,
      solarFarmId,
      nameSearchTerm,
      offset,
      limit,
    ],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      return await api.getMissionTasksWithMissionsBySolarFarmIdV1MissionTasksGet(
        {
          solarFarmId,
          orgId,
          limit: limit ? limit : GET_MISSION_TASKS_PAGINATION_LIMIT,
          offset: offset ? offset : 0,
          nameSearchTerm: nameSearchTerm ? nameSearchTerm : undefined,
        },
      );
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const cachedQueries =
    queryClient.getQueriesData<MissionTasksOverviewWithTotalResponse>([
      'mission_tasks_with_total',
      orgId,
      solarFarmId,
      nameSearchTerm,
    ]);

  const missionTasks: MissionTaskOverviewResponse[] = cachedQueries?.reduce(
    (acc, queryData) => {
      const OFFSET_INDEX = 4;
      if (!queryData) return acc;
      const [key, data] = queryData;
      const offsetKey = key[OFFSET_INDEX] as number;

      if (!data || offsetKey > offset) return acc;
      return [...acc, ...data.missionTasks];
    },
    [],
  );

  return {
    ...query,
    data: {
      missionTasks,
      total: query?.data?.total,
    },
  };
};

export const useDeleteMissionTask = (solarFarmId?: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(MissionTasksApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  return useMutation<number, ResponseError, number>({
    mutationFn: async (missionTaskId: number) => {
      return await api.deleteMissionTaskByIdV1MissionTasksIdDelete({
        id: missionTaskId,
        solarFarmId,
        orgId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['mission_tasks_with_total', orgId, solarFarmId],
      });
    },
  });
};

export const useCreateMissionTask = (solarFarmId: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(MissionTasksApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });
  return useMutation<
    MissionTaskOverviewResponse,
    ResponseError,
    MissionTaskInput
  >({
    mutationFn: async (missionTaskInput: MissionTaskInput) => {
      return api.createMissionTaskV1MissionTasksPost({
        orgId,
        solarFarmId,
        missionTaskInput,
      });
    },
    onSuccess: result => {
      queryClient.invalidateQueries({
        queryKey: ['mission_tasks_with_total', orgId, solarFarmId],
      });
      queryClient.setQueryData(
        ['mission_task', orgId, solarFarmId, result.id],
        result,
      );
    },
  });
};

export const useGetMissionTask = (
  id: number,
  solarFarmId?: number,
  onError?: () => void,
) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;

  const api = useApi(MissionTasksApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  return useQuery({
    queryKey: ['mission_task', orgId, solarFarmId, id],
    queryFn: async () => {
      if (!orgId || !solarFarmId || !id) return null;
      const result = await api.getMissionTaskByIdV1MissionTasksIdGet({
        id,
        solarFarmId,
        orgId,
      });
      return result;
    },
    retry(failureCount, error: ResponseError) {
      if ([403, 404].includes(error.response.status)) return false;
      return failureCount < 3;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: onError,
  });
};
