// Styled Components
import { Row } from '@raptormaps/layout';
import { Icon } from '@raptormaps/icon';
import { Text } from '@raptormaps/text';
import { ContentBlock } from '@raptormaps/content-block';
import { Button } from '@raptormaps/button';

// Components
import { IconTelemetry } from '../Shared/IconTelemetry';
import { DockCoverStateTelemetry } from '@/pages/Overview/Shared/DockCoverStateTelemetry';

// Telemetry
import { DockDevice } from '@/shared/types/devices';

interface DockSidebarTelemetryProps {
  selectedDockDevice: DockDevice;
  displayLiveStreamModal: boolean;
  toggleLiveStreamModal: () => void;
}

const DockSidebarTelemetry = ({
  selectedDockDevice,
  displayLiveStreamModal,
  toggleLiveStreamModal,
}: DockSidebarTelemetryProps) => {
  const deviceSn = selectedDockDevice.deviceSn;

  return (
    <>
      <ContentBlock borders columns={1} padding="large" variant="grey">
        <Row align="center" justify="space-between">
          <Row align="center" justify="start" gutter="xxs">
            <Icon icon="HouseDrone" />
            <Text variant="paragraph_medium" align="left">
              {selectedDockDevice.deviceName}
            </Text>
          </Row>
          <DockCoverStateTelemetry deviceSn={deviceSn} />
        </Row>
      </ContentBlock>
      <ContentBlock
        columns={2}
        padding="large"
        variant="white"
        style={{ boxShadow: 'unset' }}
      >
        <IconTelemetry
          telemetryKey="windspeed"
          deviceSn={selectedDockDevice.deviceSn}
        />
        <IconTelemetry
          telemetryKey="environmentTemperature"
          deviceSn={selectedDockDevice.deviceSn}
        />
        <IconTelemetry
          telemetryKey="rainfall"
          deviceSn={selectedDockDevice.deviceSn}
        />
        <IconTelemetry
          telemetryKey="internetSpeed"
          deviceSn={selectedDockDevice.deviceSn}
        />
      </ContentBlock>
      <Button
        icon="CameraWeb"
        size="large"
        variant="tertiary"
        style={{ marginBottom: '16px' }}
        iconPosition="right"
        onClick={() => toggleLiveStreamModal()}
      >
        <Text variant="paragraph_large_bold">
          {displayLiveStreamModal
            ? 'Close Dock Livestream'
            : 'Open Dock Livestream'}
        </Text>
      </Button>
    </>
  );
};

export default DockSidebarTelemetry;
