// External Dependencies
import { useState, useMemo } from 'react';
import { FormikProps } from 'formik';
// Raptor UI
import { DropdownMenu, DropdownOption } from '@raptormaps/dropdown-menu';
import { Tabs } from '@raptormaps/tabs';
import { Text, TextVariant } from '@raptormaps/text';
import { useToast } from '@raptormaps/toast';

// Internal Dependencies
import {
  FormParameters,
  FlightPlanningFormikValues,
} from '@/shared/types/missions.d';

import { CombinedFlightModeType } from '@/shared/types/tempMissions';

import {
  NUMBER_INPUT_STEP,
  FLIGHT_MODE_DEFAULTS,
  DEFAULT_SPEED_SETTINGS,
} from '@/pages/MissionPlanner/missionConstants';
import {
  FullRowTabs,
  QuarterWidthTextInput,
  FormLabel,
  MinMax,
  FullWidthGridSection,
  GridColumnOneSpanFour,
  GridColumnOneSpanTwo,
  FieldErrorText,
} from '../../../MissionParametersSection.styles';
import { SpeedControlModeType } from '@raptormaps/raptor-flight-client-ts';
import { DropdownButton } from '@/shared/styles/input.styles';
import {
  SPEED_CONTROL_TO_DISPLAY_NAME,
  DISPLAY_NAME_TO_SPEED_CONTROL,
} from '@/shared/constants/missionLookups';

interface SpeedControllerProps {
  handleCalculateWaypoints: () => void;
  formik: FormikProps<FlightPlanningFormikValues>;
  flightMode: CombinedFlightModeType;
  formParameters: FormParameters;
  handleInputScroll: (e: React.WheelEvent<HTMLInputElement>) => void;
  handleEnterPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const SpeedController = ({
  handleCalculateWaypoints,
  formik,
  flightMode,
  formParameters,
  handleInputScroll,
  handleEnterPress,
}: SpeedControllerProps) => {
  const toast = useToast();
  const { values, handleChange, errors } = formik;
  const [isCustomEnabled, setIsCustomEnabled] = useState<boolean>(
    !(
      FLIGHT_MODE_DEFAULTS[flightMode].speedControlMode ===
        values.speedControlMode &&
      (FLIGHT_MODE_DEFAULTS[flightMode].flightSpeed
        ? FLIGHT_MODE_DEFAULTS[flightMode].flightSpeed === values.flightSpeed
        : FLIGHT_MODE_DEFAULTS[flightMode].cameraInterval ===
          values.cameraInterval)
    ),
  );

  const SPEED_CONTROLS = Object.values(SPEED_CONTROL_TO_DISPLAY_NAME);

  const isCameraInterval = useMemo(
    () => values.speedControlMode === SpeedControlModeType.Shutter,
    [values.speedControlMode],
  );
  const preventChange = useMemo(
    () =>
      (isCameraInterval && errors.cameraInterval) ||
      (!isCameraInterval && errors.flightSpeed),
    [isCameraInterval, errors.cameraInterval, errors.flightSpeed],
  );

  const handleSetToDefault = () => {
    setIsCustomEnabled(false);
    DEFAULT_SPEED_SETTINGS[flightMode].forEach(target => {
      handleChange({ target: target });
    });
  };

  const photoSpeedOptions: DropdownOption[] = [
    {
      onSelect: () => {
        handleSetToDefault();
      },
      value: 'Default',
      variant: 'option',
      style: { fontSize: '14px', justifyContent: 'start' },
    },
    {
      onSelect: () => {
        setIsCustomEnabled(true);
      },
      value: 'Custom',
      variant: 'option',
      style: { fontSize: '14px', justifyContent: 'start' },
    },
  ];

  const handleClick = (index: number) => {
    // if the user is changing from the default speed setting switch to custom
    setIsCustomEnabled(true);
    // if there is an active error for the current input trigger a toaster
    preventChange
      ? toast.error(
          'There needs to be a valid value in the Interval / Speed input before being able to switch Speed Control modes',
        )
      : // else update the speed control mode
        handleChange({
          target: {
            name: 'speedControlMode',
            value: DISPLAY_NAME_TO_SPEED_CONTROL[SPEED_CONTROLS[index]],
          },
        });
  };

  return (
    <>
      <FullWidthGridSection>
        <GridColumnOneSpanFour>
          <FormLabel variant="label_small">Speed Controller</FormLabel>
          <FullRowTabs>
            <Tabs
              handleClick={(index: number) => {
                handleClick(index);
              }}
              items={SPEED_CONTROLS}
              activeIndex={SPEED_CONTROLS.indexOf(
                SPEED_CONTROL_TO_DISPLAY_NAME[values.speedControlMode],
              )}
            />
          </FullRowTabs>
        </GridColumnOneSpanFour>
        <GridColumnOneSpanTwo>
          <FormLabel overflow="visible" variant="label_small">
            {isCameraInterval
              ? 'Camera Interval (seconds)'
              : 'Flight Speed (meters/second)'}
          </FormLabel>
          <DropdownMenu
            menuContentProps={{
              align: 'start',
              sideOffset: 0,
              side: 'bottom',
              style: {
                maxHeight: '70vh',
                width: '219px',
                minWidth: '120px',
                marginTop: 0,
              },
            }}
            items={photoSpeedOptions}
            modal={false}
          >
            <DropdownButton size="small" variant="secondary">
              {isCustomEnabled ? 'Custom' : 'Default'}
            </DropdownButton>
          </DropdownMenu>
        </GridColumnOneSpanTwo>
        <QuarterWidthTextInput
          style={{ marginTop: '6.5px' }}
          gridColumn={3}
          label={' '}
          inputSize="small"
          inputStyle="white-box"
          inputType="number"
          value={isCameraInterval ? values.cameraInterval : values.flightSpeed}
          onWheel={handleInputScroll}
          onBlur={handleCalculateWaypoints}
          step={NUMBER_INPUT_STEP}
          min={
            isCameraInterval
              ? formParameters.cameraIntervalInput.min
              : formParameters.flightSpeedInput.min
          }
          max={
            isCameraInterval
              ? formParameters.cameraIntervalInput.max
              : formParameters.flightSpeedInput.max
          }
          onChange={handleChange}
          onKeyDownCapture={handleEnterPress}
          name={isCameraInterval ? 'cameraInterval' : 'flightSpeed'}
          disabled={!isCustomEnabled}
          className={`${
            errors.cameraInterval || errors.flightSpeed ? 'is-invalid' : ''
          }`}
        />
        <MinMax gridColumn={4}>
          <Text variant="paragraph_small">
            Min:{' '}
            {isCameraInterval
              ? formParameters.cameraIntervalInput.min
              : formParameters.flightSpeedInput.min}
          </Text>
          <Text variant="paragraph_small">
            Max:{' '}
            {isCameraInterval
              ? formParameters.cameraIntervalInput.max
              : formParameters.flightSpeedInput.max}
          </Text>
        </MinMax>
      </FullWidthGridSection>
      <FieldErrorText variant={TextVariant.paragraph_small}>
        {isCameraInterval ? errors.cameraInterval : errors.flightSpeed}
      </FieldErrorText>
      <FieldErrorText variant={TextVariant.paragraph_small}>
        {isCameraInterval ? errors.flightSpeed : errors.cameraInterval}
      </FieldErrorText>
    </>
  );
};

export default SpeedController;
