import { MissionResponse } from '@raptormaps/raptor-flight-client-ts';

import {
  MissionInput,
  FeatureCollectionInput,
  FlightModeType,
  OverlapType,
  CameraAngleMode,
} from '@raptormaps/raptor-flight-client-ts';
import { MISSION_JSON_VERSION } from '@/pages/MissionPlanner/missionConstants';
import {
  FlightPlanningFormikValues,
  MissionFileType,
  PointType,
} from '@/shared/types/missions.d';

import { CombinedFlightModeType } from '@/shared/types/tempMissions';

import { getFieldOfViewSpacing } from '../../../utils/utils';
import {
  featureCollection,
  point,
  FeatureCollection,
  Polygon,
  Feature,
} from '@turf/helpers';

interface GenerateMissionInputParams {
  missionName?: string;
  values: FlightPlanningFormikValues;
  center: PointType;
  markerLngLat?: number[];
  safeTakeoffAltitude?: number;
  zoom: number;
  waypoints: FeatureCollection;
  polygon: Feature<Polygon>;
  flightMode: CombinedFlightModeType;
}
export const generateMissionInput = ({
  missionName,
  values,
  safeTakeoffAltitude,
  center,
  markerLngLat,
  zoom,
  waypoints,
  polygon,
  flightMode,
}: GenerateMissionInputParams): MissionInput => {
  const frontSpacing = getFieldOfViewSpacing(
    values.altitude,
    values.fieldOfViewHorizontal,
    values.frontOverlap,
  );

  const sideSpacing = getFieldOfViewSpacing(
    values.altitude,
    values.fieldOfViewVertical,
    values.sideOverlap,
  );

  const flightMap = featureCollection([polygon]);
  const mapCenter = point([center.lng, center.lat]).geometry;
  const takeoffPoint = markerLngLat ? point(markerLngLat).geometry : null;

  return {
    missionName: missionName ? missionName : values.missionName,
    altitude: values.altitude,
    altitudeOffset: values.altitudeOffset,
    altitudeOffsetBool: values.altitudeOffsetBool,
    cameraInterval: values.cameraInterval,
    cameraAngleMode: values.cameraAngleMode,
    cameraPitchAngle: values.cameraAngle,
    drone: values.drone,
    flightAngle: values.flightAngle,
    flightMap,
    flightSpeed: values.flightSpeed,
    frontOverlap: values.frontOverlap,
    frontSpacing: frontSpacing,
    mapCenter,
    mapZoom: zoom,
    mode: flightMode as FlightModeType,
    overlapMode: OverlapType.Overlap,
    safeTakeoffAltitude,
    sensorFovHeight: values.fieldOfViewVertical,
    sensorFovWidth: values.fieldOfViewHorizontal,
    sensorName: values.cameraType,
    sideOverlap: values.sideOverlap,
    sideSpacing: sideSpacing,
    speedControl: values.speedControlMode,
    takeoffPoint,
    terrainFollow: values.terrainFollowBool,
    units: values.userUnits,
    version: MISSION_JSON_VERSION,
    waypoints: waypoints as FeatureCollectionInput,
  };
};

export const parseToMissionFileType = (
  missionResponse: MissionResponse,
): MissionFileType => {
  if (!missionResponse) return null;
  return {
    mission: {
      missionName: missionResponse.missionName,
      flightMode: missionResponse.mode,
      drone: missionResponse.drone,
      altitude: missionResponse.altitude,
      safeTakeoffAltitude: missionResponse.safeTakeoffAltitude as number | null,
      altitudeOffset: missionResponse.altitudeOffset,
      altitudeOffsetBool: missionResponse.altitudeOffsetBool,
      cameraAngle: missionResponse.cameraPitchAngle,
      // This conditional is incase we need to roll back the DB to before we supported cameraAngleMode
      cameraAngleMode: missionResponse.cameraAngleMode
        ? missionResponse.cameraAngleMode
        : CameraAngleMode.Manual,
      cameraInterval: missionResponse.cameraInterval,
      flightAngle: missionResponse.flightAngle,
      flightMap: missionResponse.flightMap.features[0] as Feature<Polygon>,
      flightSpeed: missionResponse.flightSpeed,
      frontOverlap: missionResponse.frontOverlap,
      frontSpacing: missionResponse.frontSpacing,
      mapCenter: {
        lng: missionResponse.mapCenter?.coordinates[0],
        lat: missionResponse.mapCenter?.coordinates[1],
      },
      mapZoom: missionResponse.mapZoom,
      overlapMode: missionResponse.overlapMode,
      sensor: {
        fieldOfViewHeight: missionResponse.sensorFovHeight,
        fieldOfViewWidth: missionResponse.sensorFovWidth,
        name: missionResponse.sensorName,
      },
      sideOverlap: missionResponse.sideOverlap,
      sideSpacing: missionResponse.sideSpacing,
      solarFarm: {
        id: missionResponse.solarFarmId,
        name: missionResponse.solarFarmId?.toString(),
      },
      speedControlMode: missionResponse.speedControl,
      takeOffPointCenter: missionResponse.takeoffPoint
        ? {
            lng: missionResponse.takeoffPoint?.coordinates[0],
            lat: missionResponse.takeoffPoint?.coordinates[1],
          }
        : null,
      terrainFollowBool: missionResponse.terrainFollow,
      userUnits: missionResponse.units,
    },
    version: MISSION_JSON_VERSION,
    waypoints: null,
  };
};
