// React
import { FormikProps } from 'formik';

// Components
import SpeedController from './components/SpeedController';
import CameraController from './components/CameraController/CameraController';

// Types
import {
  FormParameters,
  FlightPlanningFormikValues,
} from '@/shared/types/missions.d';

import { CombinedFlightModeType } from '@/shared/types/tempMissions';

import { CameraType } from '@raptormaps/raptor-flight-client-ts';
interface AdvancedSettingsSectionProps {
  handleCalculateWaypoints: () => void;
  formik: FormikProps<FlightPlanningFormikValues>;
  flightMode: CombinedFlightModeType;
  setDisplayFrontOverlap: (frontOverlap: number) => void;
  setDisplaySideOverlap: (sideOverlap: number) => void;
  displayFrontOverlap: number;
  displaySideOverlap: number;
  formParameters: FormParameters;
  handleInputScroll: (e: React.WheelEvent<HTMLInputElement>) => void;
  handleEnterPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleDroneCameraTypeUpdated: (drone: string, cameraType: CameraType) => void;
}

const AdvancedSettingsSection = ({
  formik,
  flightMode,
  setDisplayFrontOverlap,
  setDisplaySideOverlap,
  displayFrontOverlap,
  displaySideOverlap,
  handleCalculateWaypoints,
  formParameters,
  handleInputScroll,
  handleEnterPress,
  handleDroneCameraTypeUpdated,
}: AdvancedSettingsSectionProps) => {
  return (
    <>
      <CameraController
        formik={formik}
        setDisplayFrontOverlap={setDisplayFrontOverlap}
        setDisplaySideOverlap={setDisplaySideOverlap}
        displaySideOverlap={displaySideOverlap}
        displayFrontOverlap={displayFrontOverlap}
        handleCalculateWaypoints={handleCalculateWaypoints}
        handleEnterPress={handleEnterPress}
        formParameters={formParameters}
        handleInputScroll={handleInputScroll}
        handleDroneCameraTypeUpdated={handleDroneCameraTypeUpdated}
      />
      <SpeedController
        formik={formik}
        flightMode={flightMode}
        handleInputScroll={handleInputScroll}
        handleEnterPress={handleEnterPress}
        handleCalculateWaypoints={handleCalculateWaypoints}
        formParameters={formParameters}
      />
    </>
  );
};

export default AdvancedSettingsSection;
