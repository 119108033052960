import {
  CameraAngleMode,
  DroneType,
  FlightModeType,
  CameraType,
  SpeedControlModeType,
} from '@raptormaps/raptor-flight-client-ts';
import {
  CombinedFlightModeType,
  TemporaryFlightModeType,
} from '@/shared/types/tempMissions';

import {
  FlightModeToWaypointTurnMode,
  DataCollectionType,
  DataCollectionTypeToInspectionType,
} from '../types/missions';

export const DRONE_TYPE_TO_DISPLAY_NAME: {
  [key in DroneType]: string;
} = {
  [DroneType.M3t]: 'DJI - M3T',
  [DroneType.M30t]: 'DJI - M30T',
  [DroneType.Custom]: 'Custom',
  [DroneType.MannedAirplane]: 'Manned Airplane',
  [DroneType.M30tDock]: 'DJI - M30T Dock',
  [DroneType.M3tdDock]: 'DJI - M3TD Dock',
};

export const CAMERA_ANGLE_MODE_TO_DISPLAY_NAME: {
  [key in CameraAngleMode]: string;
} = {
  [CameraAngleMode.Manual]: 'Manual',
  [CameraAngleMode.FollowTracker]: 'Follow Tracker',
};

export const DISPLAY_NAME_TO_CAMERA_ANGLE_MODE: {
  [key: string]: CameraAngleMode;
} = {
  Manual: CameraAngleMode.Manual,
  'Follow Tracker': CameraAngleMode.FollowTracker,
};

export const FLIGHT_MODE_TO_DISPLAY_NAME: {
  [key in CombinedFlightModeType]: string;
} = {
  [FlightModeType.Standard]: 'Standard',
  [FlightModeType.Comprehensive]: 'Comprehensive',
  [FlightModeType.Oblique]: 'Oblique',
  [FlightModeType.Perimeter]: 'Perimeter',
  [FlightModeType.Wiring]: 'Wiring',
  [FlightModeType.Cablines]: 'CABLines',
  [FlightModeType.Iimode]: 'Instant',
  [FlightModeType.Custom]: 'Custom',
  [FlightModeType.Airplane]: 'Airplane',
  [FlightModeType.Overview]: 'Overview',
  [FlightModeType.Orthomosaic]: 'Ortho',
  [TemporaryFlightModeType.SquareOrbital]: 'Square Orbital',
};

export const DISPLAY_NAME_TO_FLIGHT_MODE: {
  [key: string]: CombinedFlightModeType;
} = {
  Standard: FlightModeType.Standard,
  Comprehensive: FlightModeType.Comprehensive,
  Oblique: FlightModeType.Oblique,
  Perimeter: FlightModeType.Perimeter,
  Wiring: FlightModeType.Wiring,
  CABLines: FlightModeType.Cablines,
  Instant: FlightModeType.Iimode,
  Custom: FlightModeType.Custom,
  Airplane: FlightModeType.Airplane,
  Overview: FlightModeType.Overview,
  Ortho: FlightModeType.Orthomosaic,
  'Square Orbital': TemporaryFlightModeType.SquareOrbital,
};

export const CAMERA_TYPE_TO_DISPLAY_NAME: {
  [key in CameraType]: string;
} = {
  [CameraType.Rgb]: 'RGB Wide',
  [CameraType.Thermal]: 'IR',
};

export const SPEED_CONTROL_TO_DISPLAY_NAME: {
  [key in SpeedControlModeType]: string;
} = {
  [SpeedControlModeType.Shutter]: 'Camera Interval',
  [SpeedControlModeType.Flight]: 'Flight Speed',
};

export const DISPLAY_NAME_TO_SPEED_CONTROL: {
  [key: string]: SpeedControlModeType;
} = {
  'Camera Interval': SpeedControlModeType.Shutter,
  'Flight Speed': SpeedControlModeType.Flight,
};

export const INSPECTION_TYPE_TO_DATA_COLLECTION_TYPE: {
  [key in CombinedFlightModeType]: DataCollectionType;
} = {
  [FlightModeType.Standard]: 'Area',
  [FlightModeType.Comprehensive]: 'Area',
  [FlightModeType.Oblique]: 'Area',
  [FlightModeType.Perimeter]: 'Area',
  [FlightModeType.Wiring]: 'Area',
  [FlightModeType.Iimode]: 'Area',
  [FlightModeType.Custom]: 'Area',
  [FlightModeType.Cablines]: 'Linear',
  [FlightModeType.Airplane]: 'Area',
  [FlightModeType.Overview]: 'Area',
  [FlightModeType.Orthomosaic]: 'Area',
  [TemporaryFlightModeType.SquareOrbital]: 'Area',
};

export const DATA_COLLECTION_TYPE_TO_INSPECTION_TYPE: DataCollectionTypeToInspectionType =
  {
    Area: [
      FlightModeType.Standard,
      FlightModeType.Comprehensive,
      FlightModeType.Oblique,
      FlightModeType.Perimeter,
      FlightModeType.Wiring,
      FlightModeType.Iimode,
      FlightModeType.Custom,
      FlightModeType.Orthomosaic,
      TemporaryFlightModeType.SquareOrbital,
    ],
    Linear: [FlightModeType.Cablines],
  };

export const FLIGHT_MODE_TO_WAYPOINT_TURN_MODE: FlightModeToWaypointTurnMode = {
  [FlightModeType.Standard]: 'coordinateTurn',
  [FlightModeType.Comprehensive]: 'coordinateTurn',
  [FlightModeType.Overview]: 'coordinateTurn',
  [FlightModeType.Oblique]: 'coordinateTurn',
  [FlightModeType.Perimeter]: 'coordinateTurn',
  [FlightModeType.Wiring]: 'coordinateTurn',
  [FlightModeType.Cablines]: 'coordinateTurn',
  [FlightModeType.Iimode]: 'toPointAndStopWithContinuityCurvature',
  [FlightModeType.Custom]: 'coordinateTurn',
  [FlightModeType.Airplane]: 'coordinateTurn',
  [FlightModeType.Orthomosaic]: 'coordinateTurn',
  [TemporaryFlightModeType.SquareOrbital]:
    'toPointAndPassWithContinuityCurvature',
};

export enum ActionTrigger {
  multipleTiming = 'multipleTiming',
  multipleDistance = 'multipleDistance',
}
