import styled from 'styled-components';

// RaptorUI
import { Text } from '@raptormaps/text';

export const InputHelperText = styled(Text)`
  color: ${props => props.color};
  margin-top: -15px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0.25px;
  font-weight: 500;
`;
