import { useEffect } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useLocation } from 'react-router-dom';
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';
import {
  TelemetryWebsocketState,
  useTelemetryStore,
} from '../stores/TelemetryWebsocket/TelemetryWebsocketStore';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DolbyLivestreamState,
  useDolbyLivestreamStore,
} from '../stores/DolbyLivestreamStore';

interface useLiveDeviceConnectionProps {
  isGrowthbookLoading: boolean;
}

export const useLiveDeviceConnection = ({
  isGrowthbookLoading,
}: useLiveDeviceConnectionProps) => {
  const useTelemetryWebsocket = useFeatureIsOn(
    GrowthbookFlags.TELEMETRY_WEBSOCKET,
  );
  const useLivestream = useFeatureIsOn(GrowthbookFlags.LIVE_STREAM);
  const location = useLocation();
  const { getAccessTokenSilently } = useAuth0();

  // Pathnames that should open a websocket connection
  const telemetryWebsocketPathnames = [
    '/overview',
    '/live-stream',
    '/plan-task',
  ];

  const livestreamPathnames = ['/live-stream', '/overview'];

  const openSocket = useTelemetryStore(
    (state: TelemetryWebsocketState) => state.actions.openSocket,
  );
  const closeSocket = useTelemetryStore(
    (state: TelemetryWebsocketState) => state.actions.closeSocket,
  );
  const closeDolbyStream = useDolbyLivestreamStore(
    (state: DolbyLivestreamState) => state.closeDolbyStream,
  );
  const dolbyIsSubscribed = useDolbyLivestreamStore(
    (state: DolbyLivestreamState) => state.isSubscribed,
  );

  useEffect(() => {
    // Growthbook should be loaded before checking for the feature flag
    if (
      !isGrowthbookLoading &&
      useTelemetryWebsocket &&
      telemetryWebsocketPathnames.some(basePath => {
        return location.pathname.startsWith(basePath);
      })
    ) {
      getAccessTokenSilently().then(token => {
        openSocket(token);
      });
    } else {
      closeSocket();
    }
  }, [location, isGrowthbookLoading]);

  // Close Dolby stream when navigating away from the live stream page
  useEffect(() => {
    if (
      !isGrowthbookLoading &&
      useLivestream &&
      !livestreamPathnames.some(basePath => {
        return location.pathname.startsWith(basePath);
      }) &&
      dolbyIsSubscribed
    ) {
      closeDolbyStream();
    }
  }, [location, isGrowthbookLoading]);
};
