import {
  CameraAngleMode,
  DroneType,
  FlightModeType,
  CameraType,
  SpeedControlModeType,
  UnitType,
  OverlapType,
} from '@raptormaps/raptor-flight-client-ts';
import { TemporaryFlightModeType } from '@/shared/types/tempMissions';
import {
  DroneCameraTypeDefaults,
  FlightModeDefaults,
  CalibrationObject,
  IntervalometerObject,
  DataCollectionType,
} from '@/shared/types/missions.d';

// update each time there is a new version
export const MISSION_JSON_VERSION = '4.0.0';
export const ACCEPTED_ERRORS = ['missionName', 'terrainFollowBool'];
export const SETTINGS_OPTIONS = ['Basic', 'Advanced'];
export const DATA_COLLECTION_OPTIONS: DataCollectionType[] = ['Area', 'Linear'];
export const NUMBER_INPUT_STEP = 0.00001;
export const PERCENT_INPUT_STEP = 1;
export const METERS_TO_FEET = 3.28084;
export const MS_TO_MPH = 2.23694; // m/s to mph

export const DEFAULT_SPEED_SETTINGS = {
  [FlightModeType.Standard]: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SpeedControlModeType.Shutter,
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'flightSpeed',
      value: 5.71,
    },
  ],
  [FlightModeType.Comprehensive]: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SpeedControlModeType.Shutter,
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'flightSpeed',
      value: 5.71,
    },
  ],
  [FlightModeType.Oblique]: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SpeedControlModeType.Flight,
    },
    {
      // sets the default speed based on the above control mode
      name: 'flightSpeed',
      value: 13.5,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'cameraInterval',
      value: 2.608,
    },
  ],
  [FlightModeType.Perimeter]: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SpeedControlModeType.Flight,
    },
    {
      // sets the default speed based on the above control mode
      name: 'flightSpeed',
      value: 5.5,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'cameraInterval',
      value: 3.935,
    },
  ],
  [FlightModeType.Wiring]: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SpeedControlModeType.Flight,
    },
    {
      // sets the default speed based on the above control mode
      name: 'flightSpeed',
      value: 3.2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'cameraInterval',
      value: 1.803,
    },
  ],
  [FlightModeType.Iimode]: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SpeedControlModeType.Shutter,
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'flightSpeed',
      value: 5.71,
    },
  ],
  [FlightModeType.Cablines]: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SpeedControlModeType.Shutter,
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'flightSpeed',
      value: 5.71,
    },
  ],
  [FlightModeType.Custom]: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SpeedControlModeType.Shutter,
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'flightSpeed',
      value: 5.71,
    },
  ],
  [FlightModeType.Orthomosaic]: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SpeedControlModeType.Shutter,
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
  ],
  [TemporaryFlightModeType.SquareOrbital]: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SpeedControlModeType.Shutter,
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'flightSpeed',
      value: 2.097,
    },
  ],
};

export const DRONE_CAMERA_DEFAULTS: DroneCameraTypeDefaults = {
  [DroneType.M3t]: {
    [CameraType.Thermal]: {
      fieldOfViewHorizontal: 49.27437,
      fieldOfViewVertical: 40.68,
    },
    [CameraType.Rgb]: {
      fieldOfViewHorizontal: 71.619,
      fieldOfViewVertical: 56.7227,
    },
  },
  [DroneType.M30t]: {
    [CameraType.Thermal]: {
      fieldOfViewHorizontal: 49.27437,
      fieldOfViewVertical: 40.68,
    },
    [CameraType.Rgb]: {
      fieldOfViewHorizontal: 71.61972,
      fieldOfViewVertical: 56.72282,
    },
  },
  [DroneType.Custom]: {
    [CameraType.Thermal]: {},
    [CameraType.Rgb]: {},
  },
  [DroneType.MannedAirplane]: {
    [CameraType.Thermal]: {
      fieldOfViewHorizontal: 17.46,
      fieldOfViewVertical: 14.01,
    },
    [CameraType.Rgb]: {
      fieldOfViewHorizontal: 23.86,
      fieldOfViewVertical: 16.04,
    },
  },

  [DroneType.M30tDock]: {
    [CameraType.Thermal]: {
      fieldOfViewHorizontal: 49.27437,
      fieldOfViewVertical: 40.68,
    },
    [CameraType.Rgb]: {
      fieldOfViewHorizontal: 71.61972,
      fieldOfViewVertical: 56.7227,
    },
  },

  [DroneType.M3tdDock]: {
    [CameraType.Thermal]: {
      fieldOfViewHorizontal: 49.27437,
      fieldOfViewVertical: 40.68,
    },
    [CameraType.Rgb]: {
      fieldOfViewHorizontal: 69.63177281,
      fieldOfViewVertical: 55.09057208,
    },
  },
};

export const FLIGHT_MODE_DEFAULTS: FlightModeDefaults = {
  [FlightModeType.Perimeter]: {
    altitude: 50,
    cameraAngle: -45,
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: CameraType.Rgb,
    flightSpeed: 5.5,
    frontOverlap: 0.7,
    speedControlMode: SpeedControlModeType.Flight,
  },
  [FlightModeType.Oblique]: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: CameraType.Thermal,
    frontOverlap: 0.8,
    sideOverlap: 0.2,
    altitude: 122,
    cameraAngle: -25,
    speedControlMode: SpeedControlModeType.Flight,
    flightSpeed: 13.5,
  },
  [FlightModeType.Standard]: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: CameraType.Thermal,
    frontOverlap: 0.7,
    sideOverlap: 0.2,
    altitude: 41.5,
    cameraAngle: -90,
    speedControlMode: SpeedControlModeType.Shutter,
    cameraInterval: 2,
  },
  [FlightModeType.Comprehensive]: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: CameraType.Thermal,
    frontOverlap: 0.7,
    sideOverlap: 0.2,
    altitude: 22.5,
    cameraAngle: -90,
    speedControlMode: SpeedControlModeType.Shutter,
    cameraInterval: 2,
  },
  [FlightModeType.Cablines]: {
    cameraAngleMode: CameraAngleMode.Manual,
    drone: DroneType.M3t,
    cameraType: CameraType.Rgb,
    ...DRONE_CAMERA_DEFAULTS[DroneType.M3t][CameraType.Rgb],
    speedControlMode: SpeedControlModeType.Shutter,
    frontOverlap: 0.5,
    altitude: 4.9,
    flightAngle: 90,
    cameraInterval: 2,
    cameraAngle: -40,
  },
  [FlightModeType.Iimode]: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: CameraType.Rgb,
    drone: DroneType.M3t,
    ...DRONE_CAMERA_DEFAULTS[DroneType.M3t][CameraType.Rgb],
    speedControlMode: SpeedControlModeType.Shutter,
    frontOverlap: 0.7,
    sideOverlap: 0.7,
    altitude: 41.5,
    cameraInterval: 2,
    cameraAngle: -90,
  },
  [FlightModeType.Wiring]: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: CameraType.Rgb,
    drone: DroneType.M3t,
    ...DRONE_CAMERA_DEFAULTS[DroneType.M3t][CameraType.Rgb],
    frontOverlap: 0.35,
    sideOverlap: 0.11,
    altitude: 6.15,
    flightAngle: 90,
    flightSpeed: 3.2,
    cameraAngle: -45,
    speedControlMode: SpeedControlModeType.Flight,
  },
  [FlightModeType.Custom]: {
    cameraAngleMode: CameraAngleMode.Manual,
  },
  [FlightModeType.Overview]: {
    cameraAngleMode: CameraAngleMode.Manual,
  },
  [FlightModeType.Airplane]: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: CameraType.Thermal,
    ...DRONE_CAMERA_DEFAULTS[DroneType.MannedAirplane][CameraType.Thermal],
    frontOverlap: 0.7,
    sideOverlap: 0.2,
    altitude: 457.2,
    cameraAngle: -90,
    speedControlMode: SpeedControlModeType.Shutter,
    cameraInterval: 2,
  },
  [FlightModeType.Orthomosaic]: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: CameraType.Rgb,
    frontOverlap: 0.7,
    sideOverlap: 0.7,
    altitude: 91.44,
    cameraAngle: -90,
    speedControlMode: SpeedControlModeType.Shutter,
    cameraInterval: 2,
  },
  [TemporaryFlightModeType.SquareOrbital]: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: CameraType.Rgb,
    frontOverlap: 0.2,
    sideOverlap: 0.7,
    altitude: 15.24,
    cameraAngle: -30,
    speedControlMode: SpeedControlModeType.Shutter,
    cameraInterval: 2,
  },
};

export const DEFAULT_FLIGHT_MISSION_FILE = {
  mission: {
    flightMode: FlightModeType.Standard,
    drone: DroneType.M3t,
    altitude: 41.5, // meters
    safeTakeoffAltitude: 50, // meters
    altitudeOffset: 0, // meters - added v2.1.0 optional
    altitudeOffsetBool: false, // added v2.1.0 optional
    cameraAngle: -90, // -180 to 180 deg (with 0 being horizon, -90 being nadir)
    cameraAngleMode: CameraAngleMode.Manual, // manual or follow_tracker
    cameraInterval: 2.0, // seconds
    flightAngle: 90, // 0 to 360 deg (heading)
    flightMap: null, // polygon or perimeter
    flightSpeed: 5.71, // meters/second
    frontOverlap: 0.7,
    frontSpacing: 11.419663384522305,
    mapCenter: null,
    mapZoom: 15, // 0 to 23?
    overlapMode: OverlapType.Overlap,
    sensor: {
      fieldOfViewHeight: 40.68, // currently fov vertical
      fieldOfViewWidth: 49.27437, // currently fov horizontal
      name: CameraType.Thermal,
    },
    sideOverlap: 0.2,
    sideSpacing: 24.614818476019778,
    solarFarm: null,
    speedControlMode: SpeedControlModeType.Shutter,
    takeOffPointCenter: null,
    terrainFollowBool: false,
    userUnits: UnitType.Metric,
  },
  version: MISSION_JSON_VERSION,
  waypoints: null,
};

export const DEFAULT_CALIBRATION: CalibrationObject = {
  active: false,
  rhumbBearing: 0,
  rhumbDistance: 0,
  nonCalibratedFlightPath: null,
};

export const DEFAULT_INTERVALOMETER: IntervalometerObject = {
  intervalometerBool: false,
  waypointReductionBool: false,
  actionTriggerParam: 0,
};
