import { useState } from 'react';

// Components
import DroneSidebarTelemetry from './DroneSidebarTelemetry';
import DockSidebarTelemetry from './DockSidebarTelemetry';
import LiveStreamViewerModal from './LiveStreamViewerModal';

// Styled Components
import { SiteOverviewContainer } from '../Overview.styled';
import { Button } from '@raptormaps/button';
import { Text } from '@raptormaps/text';

// Types
import { DockDevice } from '@/shared/types/devices';
import { DeviceResponse } from '@raptormaps/raptor-flight-client-ts';
import { Feature } from '@turf/helpers';

// Live Device Connections
import {
  useDolbyLivestreamStore,
  DolbyLivestreamState,
} from '@/shared/stores/DolbyLivestreamStore';

interface DockOverviewProps {
  navigateToSite: () => void;
  dockDevice: DockDevice;
  navigateToDroneLocation: (droneLocation: Feature) => void;
}

const DockOverview = ({
  navigateToSite,
  dockDevice,
  navigateToDroneLocation,
}: DockOverviewProps) => {
  const [displayLiveStreamModal, setDisplayLiveStreamModal] = useState(false);

  // Live Stream
  const closeDolbyStream = useDolbyLivestreamStore(
    (state: DolbyLivestreamState) => state.closeDolbyStream,
  );

  const toggleLiveStreamModal = () => {
    if (displayLiveStreamModal) {
      setDisplayLiveStreamModal(false);
      closeLivestream();
    } else {
      setDisplayLiveStreamModal(true);
    }
  };

  const closeLivestream = () => {
    closeDolbyStream();
  };

  return (
    <>
      {dockDevice && (
        <>
          <Button
            iconPosition="left"
            icon="ArrowLeft"
            variant="tertiary"
            onClick={navigateToSite}
          >
            Back
          </Button>
          <SiteOverviewContainer>
            {dockDevice.devices?.length ? (
              dockDevice.devices.map((device: DeviceResponse) => (
                <DroneSidebarTelemetry
                  key={device.id}
                  droneDevice={device}
                  dockSn={dockDevice.deviceSn}
                  navigateToDroneLocation={navigateToDroneLocation}
                />
              ))
            ) : (
              <Text variant="paragraph_large_bold">
                No aircraft is associated to this device.
              </Text>
            )}
            <DockSidebarTelemetry
              selectedDockDevice={dockDevice}
              displayLiveStreamModal={displayLiveStreamModal}
              toggleLiveStreamModal={toggleLiveStreamModal}
            />
          </SiteOverviewContainer>
          {displayLiveStreamModal && <LiveStreamViewerModal />}
        </>
      )}
    </>
  );
};

export default DockOverview;
