// Types
import { CameraType, DroneType } from '@raptormaps/raptor-flight-client-ts';

// Constants
import { DRONE_CAMERA_DEFAULTS } from '@/pages/MissionPlanner/missionConstants';

export const calculateControlledOverlap = (
  activeCamera: CameraType,
  drone: DroneType,
  sideOverlap: number,
  frontOverlap: number,
) => {
  const FOVS = DRONE_CAMERA_DEFAULTS[drone];
  const controlledCamera =
    activeCamera === CameraType.Rgb ? CameraType.Thermal : CameraType.Rgb;

  const radians = degrees => {
    return degrees * (Math.PI / 180);
  };

  const calculateOverlap = (activeFOV, controlledFOV, overlap) => {
    return (
      1 -
      (Math.tan(radians(activeFOV) / 2) /
        Math.tan(radians(controlledFOV) / 2)) *
        (1 - overlap)
    );
  };

  const controlledSideOverlap = calculateOverlap(
    FOVS[activeCamera].fieldOfViewVertical,
    FOVS[controlledCamera].fieldOfViewVertical,
    sideOverlap,
  );
  const controlledFrontOverlap = calculateOverlap(
    FOVS[activeCamera].fieldOfViewHorizontal,
    FOVS[controlledCamera].fieldOfViewHorizontal,
    frontOverlap,
  );

  return { controlledSideOverlap, controlledFrontOverlap };
};
