// External Dependencies
import { UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// RaptorUI
import { Text } from '@raptormaps/text';
import { Button } from '@raptormaps/button';
import { Row, Stack } from '@raptormaps/layout';
import { Pill } from '@raptormaps/pill';
import { Icon } from '@raptormaps/icon';

// Internal Dependencies
import { MissionTaskPopovers } from '@/pages/MissionTasks/MissionTaskPlanner/missionTask.d';
import { ComponentLoader } from '@/shared/components/ComponentLoader/ComponentLoader';
import { SidebarBottomButtonContainer } from '../../CreateMissionTask.styles';
import {
  SidebarCloseButton,
  FullWidthRow,
} from '@/shared/styles/sidebar.styles';
import { useGetMission } from '@/shared/hooks/useMissions';
import {
  DRONE_TYPE_TO_DISPLAY_NAME,
  FLIGHT_MODE_TO_DISPLAY_NAME,
  INSPECTION_TYPE_TO_DATA_COLLECTION_TYPE,
} from '@/shared/constants/missionLookups';
import { MISSION_TASK_FORM_FIELDS } from '@/pages/MissionTasks/MissionTaskPlanner/missionTaskFormConstants';

dayjs.extend(utc);

interface MissionDetailProps {
  solarFarmId: number;
  missionTaskForm: UseFormReturn;
  missionId: number;
  backButtonPopover: MissionTaskPopovers;
  setIsSideBarOpen: (isSideBarOpen: boolean) => void;
  setVisiblePopover: (visiblePopover: MissionTaskPopovers | null) => void;
}

export const MissionDetailPopover = ({
  solarFarmId,
  missionTaskForm,
  backButtonPopover,
  setIsSideBarOpen,
  setVisiblePopover,
}: MissionDetailProps) => {
  const { setValue, watch } = missionTaskForm;
  const missionId = watch(MISSION_TASK_FORM_FIELDS.detailViewMissionId);
  const { data, error } = useGetMission(missionId, solarFarmId);

  const handleSelectMissionClick = () => {
    setValue(MISSION_TASK_FORM_FIELDS.missionIds, [missionId], {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue(MISSION_TASK_FORM_FIELDS.detailViewMissionId, null);
    setIsSideBarOpen(true);
    setVisiblePopover(backButtonPopover);
  };

  return (
    <>
      <Row align="center" justify="space-between">
        <Text variant="h4">Mission Library</Text>
        <SidebarCloseButton
          onClick={() => {
            setValue(MISSION_TASK_FORM_FIELDS.detailViewMissionId, null);
            setIsSideBarOpen(true);
            setVisiblePopover(null);
          }}
          variant="tertiary"
          size="small"
          icon="Xmark"
          aria-label="Close Mission Detail Popover"
        />
      </Row>
      <Stack align="start" gutter="lg" style={{ marginTop: '15px' }}>
        <Button
          icon="ArrowLeft"
          iconPosition="left"
          size="small"
          variant="tertiary"
          role="link"
          style={{ fontWeight: '600', marginLeft: '-14px' }}
          onClick={() => {
            setVisiblePopover(backButtonPopover);
            setValue(MISSION_TASK_FORM_FIELDS.detailViewMissionId, null);
          }}
        >
          Back
        </Button>
        {error ? (
          <Row noWrap>
            <Icon icon="TriangleExclamation" size="large" />
            <Text variant="h5">Error loading mission</Text>
          </Row>
        ) : !data ? (
          <ComponentLoader message={'Loading Mission...'} />
        ) : (
          <>
            <Text variant="h5">{data?.missionName}</Text>
            <Text variant="h6">Summary</Text>
            <FullWidthRow justify="space-between">
              <Text variant="paragraph_medium">Inspection Type</Text>
              <Text variant="paragraph_medium">
                <Pill>{FLIGHT_MODE_TO_DISPLAY_NAME[`${data?.mode}`]}</Pill>
              </Text>
            </FullWidthRow>
            <FullWidthRow justify="space-between">
              <Text variant="paragraph_medium">Aircraft Model</Text>
              <Text variant="paragraph_medium">
                {DRONE_TYPE_TO_DISPLAY_NAME[`${data?.drone}`]}
              </Text>
            </FullWidthRow>
            <FullWidthRow justify="space-between">
              <Text variant="paragraph_medium">Flight Time</Text>
              {/* TODO Calculate Flight Time */}
              <Text variant="paragraph_medium">1h 3' 10"</Text>
            </FullWidthRow>
            <FullWidthRow justify="space-between">
              <Text variant="paragraph_medium">Total Duration</Text>
              {/* TODO Calculate Total Duration */}
              <Text variant="paragraph_medium">2h 23' 30"</Text>
            </FullWidthRow>
            <FullWidthRow justify="space-between">
              <Text variant="paragraph_medium">Battery Cycles</Text>
              {/* TODO Calculate Battery Cycles */}
              <Text variant="paragraph_medium">3</Text>
            </FullWidthRow>
            <FullWidthRow justify="space-between">
              {/* TODO Calculate Total Distance */}
              <Text variant="paragraph_medium">Total Distance</Text>
              <Text variant="paragraph_medium">3564 m</Text>
            </FullWidthRow>
            <FullWidthRow justify="space-between">
              {/* TODO Calculate Max Altitude -> could be done by accessing kmz or when putting the mission on the map */}
              <Text variant="paragraph_medium">Max Altitude</Text>
              <Text variant="paragraph_medium">
                {data?.altitudeOffset + data?.altitude}
              </Text>
            </FullWidthRow>
            <FullWidthRow justify="space-between">
              <Text variant="paragraph_medium">Created</Text>
              <Text variant="paragraph_medium">
                {dayjs(data?.createdTsecs * 1000).format('MM/DD/YYYY hh:mm')}
              </Text>
            </FullWidthRow>
            <FullWidthRow justify="space-between">
              <Text variant="paragraph_medium">Last Updated</Text>
              {/* TODO Update this to last used when we start tracking usage */}
              <Text variant="paragraph_medium">
                {dayjs(data?.updatedTsecs * 1000).format('MM/DD/YYYY hh:mm')}
              </Text>
            </FullWidthRow>
          </>
        )}
      </Stack>
      {data && (
        <Stack align="start" gutter="lg" style={{ margin: '45px 0' }}>
          <Text variant="h6">Parameters</Text>
          <FullWidthRow justify="space-between">
            <Text variant="paragraph_medium">Data Collection Type</Text>
            <Text variant="paragraph_medium">
              {INSPECTION_TYPE_TO_DATA_COLLECTION_TYPE[`${data?.mode}`]}
            </Text>
          </FullWidthRow>
          <FullWidthRow justify="space-between">
            <Text variant="paragraph_medium">Inspection Type</Text>
            <Text variant="paragraph_medium">
              <Pill>{FLIGHT_MODE_TO_DISPLAY_NAME[`${data?.mode}`]}</Pill>
            </Text>
          </FullWidthRow>
          <FullWidthRow justify="space-between">
            <Text variant="paragraph_medium">Altitude</Text>
            <Text variant="paragraph_medium">{data?.altitude}</Text>
          </FullWidthRow>
          <FullWidthRow justify="space-between">
            <Text variant="paragraph_medium">Altitude Offset Option</Text>
            <Text variant="paragraph_medium">
              {data?.altitudeOffsetBool ? 'ON' : 'OFF'}
            </Text>
          </FullWidthRow>
        </Stack>
      )}
      <SidebarBottomButtonContainer justify="space-around">
        <Button
          style={{ width: '100%' }}
          onClick={handleSelectMissionClick}
          disabled={!data}
        >
          Select Mission
        </Button>
      </SidebarBottomButtonContainer>
    </>
  );
};
