import distance from '@turf/distance';
import along from '@turf/along';
import {
  point,
  lineString,
  featureCollection,
  Feature,
  LineString,
  MultiLineString,
  FeatureCollection,
  Polygon,
  Position,
} from '@turf/helpers';
import lineIntersect from '@turf/line-intersect';
import lineDistance from '@turf/line-distance';
import lineChunk from '@turf/line-chunk';
import centroid from '@turf/centroid';
import transformTranslate from '@turf/transform-translate';
import transformRotate from '@turf/transform-rotate';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import buffer from '@turf/buffer';
import polygonToLine from '@turf/polygon-to-line';
import bearing from '@turf/bearing';
import truncate from '@turf/truncate';
import midpoint from '@turf/midpoint';
import union from '@turf/union';
import length from '@turf/length';
import { Point } from 'geojson';
import destination from '@turf/destination';
import { CombinedFlightModeType } from '@/shared/types/tempMissions';
import { FlightModeType } from '@raptormaps/raptor-flight-client-ts';

function degreesToRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}

function shiftFlightArea(altitude: number, pitchAngle: number) {
  // Calculates how far the flight polygon needs to be moved to account for the angle of the camera shifting the field of view.
  // Adds 90 to the pitch angle so that 0 corresponds to pointing straight down
  const radianPitchAngle = ((90 + Number(pitchAngle)) * Math.PI) / 180.0;
  // multiplied by -1 since we're shifting in the opposite direction from the flight angle
  const shiftGPS = -1.0 * altitude * Math.tan(radianPitchAngle);

  return shiftGPS;
}

function shiftFlightAreaPolygon(
  altitude: number,
  flightAngle: number,
  pitchAngle: number,
  polygon: Feature<Polygon>,
) {
  // Calculates how far the flight polygon needs to be moved to account for the angle of the camera shifting the field of view.
  // Adds 90 to the pitch angle so that 0 corresponds to pointing straight down
  const radianPitchAngle = ((90 + Number(pitchAngle)) * Math.PI) / 180.0;
  // multiplied by -1 since we're shifting in the opposite direction from the flight angle
  const shiftGPS = -1.0 * altitude * Math.tan(radianPitchAngle);

  const shiftedPolygon = transformTranslate(polygon, shiftGPS, flightAngle, {
    units: 'meters',
  });

  console.log('shiftGPS: ' + shiftGPS);
  return shiftedPolygon;
}

function shiftFlightAreaTwoAngles(
  altitude: number,
  flightAngle: number,
  startingPitchAngle: number,
  endingPitchAngle: number,
  polygon: Feature<Polygon>,
) {
  const startingShiftedPolygon = shiftFlightAreaPolygon(
    altitude,
    flightAngle,
    startingPitchAngle,
    polygon,
  );
  const endingShiftedPolygon = shiftFlightAreaPolygon(
    altitude,
    flightAngle + 180,
    endingPitchAngle,
    polygon,
  );

  const combinedPolygons = union(startingShiftedPolygon, endingShiftedPolygon);
  // Checks whether one or multiple polygons where returned, only proceeds if there is only one polygon
  if (combinedPolygons.geometry.type === 'Polygon') {
    return combinedPolygons as Feature<Polygon>;
  } else {
    console.error(
      'Could not combine polygons into one for the shifted flight area, defaulting to drawn polygon',
    );
    return polygon;
  }
}

// Exported for testing, to be used in utils.test
export const exportedForTestingSFA = {
  shiftFlightArea,
};

// Exported for testing, to be used in utils.test
export const exportedForTestingRBB = {
  rotatedBbox,
};

function rotatedBbox(polygon: Feature<Polygon>, flightAngle: number) {
  // rotates the polygon, draws a bounding box and unrotates the bbox so its the same orientation as the polygon
  const polygonCenter = centroid(polygon);

  const rotatedPolygon = transformRotate(polygon, -flightAngle, {
    pivot: polygonCenter,
  });
  const rotatedBbox = bboxPolygon(bbox(rotatedPolygon));
  const unrotatedBbox = transformRotate(rotatedBbox, flightAngle, {
    pivot: polygonCenter,
  });
  const bboxCoords = unrotatedBbox.geometry.coordinates[0].slice(0, 4);

  return bboxCoords;
}
// Find maximum spacing between where images should be taken
export function getFieldOfViewSpacing(
  altitude: number,
  fieldOfView: number,
  overlap: number,
): number {
  const fieldOfViewRadian = (fieldOfView * Math.PI) / 180.0;
  const spacing =
    2.0 * altitude * Math.tan(fieldOfViewRadian / 2.0) * (1.0 - overlap); // m
  return spacing;
}

// Creates flight path based on inputs:
// Box is turned such that the drone is flying 'right' and 'left' in rows to take pictures while always moving 'down' for the next row.
//  1> 2> 3 v
// v6 <5 <4
//  7> 8> 9
// The box is a rectangle that completely encompasses the polygon drawn
export function createFlightPathFromPolygon(
  polygon: Feature<Polygon>,
  flightAngle: number,
  altitude: number,
  fieldOfViewVertical: number,
  sideOverlap: number,
  pitchAngle: number,
): Feature<LineString> {
  const sideDistance =
    getFieldOfViewSpacing(altitude, fieldOfViewVertical, sideOverlap) / 1000.0; // km

  const shiftGPS = shiftFlightArea(altitude, pitchAngle);
  const shiftedPolygon = transformTranslate(polygon, shiftGPS, flightAngle, {
    units: 'meters',
  });

  const gridLine = createSerpentinePathFromPolygon(
    flightAngle,
    shiftedPolygon,
    sideDistance,
  );

  return gridLine;
}

export function createFlightPathFromPolygonContinuousOperation(
  polygon: Feature<Polygon>,
  flightAngle: number,
  altitude: number,
  fieldOfViewVertical: number,
  sideOverlap: number,
  maximumPanelAngle: number,
): Feature<LineString> {
  const sideDistance =
    getFieldOfViewSpacing(altitude, fieldOfViewVertical, sideOverlap) / 1000.0; // km

  const shiftedPolygon = shiftFlightAreaTwoAngles(
    altitude,
    flightAngle,
    maximumPanelAngle - 90,
    maximumPanelAngle - 90,
    polygon,
  );

  // rotated bbox
  const gridLine = createSerpentinePathFromPolygon(
    flightAngle,
    shiftedPolygon,
    sideDistance,
  );

  return gridLine;
}

export function createFlightPathFromPolygonAirplane(
  polygon: Feature<Polygon>,
  flightAngle: number,
  altitude: number,
  fieldOfViewVertical: number,
  sideOverlap: number,
  pitchAngle: number,
): FeatureCollection<LineString> {
  const sideDistance =
    getFieldOfViewSpacing(altitude, fieldOfViewVertical, sideOverlap) / 1000.0; // km

  const shiftGPS = shiftFlightArea(altitude, pitchAngle);
  const shiftedPolygon = transformTranslate(polygon, shiftGPS, flightAngle, {
    units: 'meters',
  });

  const linesInPolygon = createParallelLinesFromPolygon(
    flightAngle,
    shiftedPolygon,
    sideDistance,
  );
  const extendedFlightLines = extendLinesForLeadInOut(
    featureCollection(linesInPolygon),
  );
  return extendedFlightLines;
}

function createSerpentinePathFromPolygon(
  flightAngle: number,
  polygon: Feature<Polygon>,
  rowSeperation: number,
) {
  const rotatedExtents = rotatedBbox(polygon, flightAngle);
  const [p0, p1, p2, p3] = rotatedExtents;
  const height = distance(point(p0), point(p3)); // km // p0-----p1
  const westLine = lineString([p0, p3]); //               |       |
  const eastLine = lineString([p1, p2]); //               p3-----p2

  // split the bounding box up into a number of flight passes
  // the number is determined by the desired side distance between
  // passes. (side is left and right of the flight path direction)
  const nLines = Math.ceil(height / rowSeperation);

  if (nLines <= 1) {
    //return {};
    return null;
  }

  const gridPoints = [];

  // loop over the flight passes and alternate which end of the
  // flight pass is the start or stop by using the flag variable
  let flag = true;

  // Starting at top create one layer at a time, moving down the "sideDistance" each time.
  for (let dist = 0; dist < height; dist += rowSeperation) {
    const westPoint = along(westLine, dist);
    const eastPoint = along(eastLine, dist);

    // determine where the flight pass intersects with the polygon boundary
    const intersects = lineIntersect(
      polygon,
      lineString([
        westPoint.geometry.coordinates,
        eastPoint.geometry.coordinates,
      ]),
    );

    if (intersects.features.length == 0) {
      continue;
    }

    // create a list of points
    // If there is one intersection add that intersection to the flight path
    else if (intersects.features.length == 1) {
      gridPoints.push(intersects.features[0].geometry.coordinates);
    }

    // If there is more than one intersection alternate the order in which the first two intersection points are added to the flight path.
    // TODO: are there any cases where there are more than two intersections? (probably if polygons are drawn weirdly)
    // TODO: check if this is the logic that is sometimes causing diagonal flight paths instead of straight lines.
    // notice how the flag determines which coordinate is the start or end
    else if (intersects.features[1].geometry && flag == true) {
      gridPoints.push(intersects.features[0].geometry.coordinates);
      gridPoints.push(intersects.features[1].geometry.coordinates);
    } else if (intersects.features[1].geometry && flag == false) {
      gridPoints.push(intersects.features[1].geometry.coordinates);
      gridPoints.push(intersects.features[0].geometry.coordinates);
    }
    // perhaps this is not the right logic for corner cases
    flag = !flag;
  }

  const gridLine = lineString(gridPoints);
  return gridLine;
}

export function createParallelLinesFromPolygon(
  flightAngle: number,
  polygon: Feature<Polygon>,
  rowSeperation: number,
) {
  const rotatedExtents = rotatedBbox(polygon, flightAngle);
  const [p0, p1, p2, p3] = rotatedExtents;
  const height = distance(point(p0), point(p3)); // km // p0-----p1
  const westLine = lineString([p0, p3]); //               |       |
  const eastLine = lineString([p1, p2]); //               p3-----p2

  const lines: Feature<LineString>[] = [];
  // Starting at top create one layer at a time, moving down the "sideDistance" each time.
  const rowCenteringOffset = (height % rowSeperation) / 2; // determines where to place first row so that first and last row are same distance from sides of polygon
  for (let dist = rowCenteringOffset; dist < height; dist += rowSeperation) {
    const westPoint = along(westLine, dist);
    const eastPoint = along(eastLine, dist);

    // determine where the flight pass intersects with the polygon boundary
    const intersects = lineIntersect(
      polygon,
      lineString([
        westPoint.geometry.coordinates,
        eastPoint.geometry.coordinates,
      ]),
    );

    if (intersects.features.length == 2) {
      lines.push(
        lineString([
          intersects.features[0].geometry.coordinates,
          intersects.features[1].geometry.coordinates,
        ]),
      );
    } else if (intersects.features.length > 2) {
      // If there are more than two intersections use the outer two to draw a line
      lines.push(
        lineString([
          intersects.features[0].geometry.coordinates,
          intersects.features.at(-1).geometry.coordinates,
        ]),
      );
    }
  }
  return lines;
}

export function extendLinesForLeadInOut(
  linesCollection: FeatureCollection<LineString>,
): FeatureCollection<LineString> {
  // extends lines by one mile on either side in the direction of the line. Input should only be straight lines.
  const extendedLines: Feature<LineString>[] = [];
  linesCollection.features.forEach(feature => {
    const bearingOfLine = bearing(
      feature.geometry.coordinates[0],
      feature.geometry.coordinates[1],
    );
    const leadIn = destination(
      feature.geometry.coordinates[0],
      1,
      bearingOfLine + 180,
      { units: 'miles' },
    );
    const leadOut = destination(
      feature.geometry.coordinates[1],
      1,
      bearingOfLine,
      { units: 'miles' },
    );
    extendedLines.push(
      lineString([leadIn.geometry.coordinates, leadOut.geometry.coordinates]),
    );
  });
  return featureCollection(extendedLines);
}

export function createStraightFlightPathFromPolygon(
  polygon: Feature<Polygon>,
  flightAngle: number,
): Feature<LineString> {
  // rotated bbox
  const rotatedExtents = rotatedBbox(polygon, flightAngle);
  const [p0, p1, p2, p3] = rotatedExtents;
  const northMidpoint = midpoint(p0, p1);
  const southMidpoint = midpoint(p3, p2);
  const midString = lineString([
    northMidpoint.geometry.coordinates,
    southMidpoint.geometry.coordinates,
  ]);
  return midString;
}

export function createInspectionFlightPathFromPolygon(
  polygon: Feature<Polygon> | null,
  altitude: number,
  fieldOfViewVertical: number,
  pitchAngle: number,
): Feature | FeatureCollection<LineString | MultiLineString> {
  // Takes the polygon input and returns a polygon shaped line offset by the buffer distance
  const convertedPitchAngleRadian = ((90 + pitchAngle) * Math.PI) / 180.0; // also changes the starting point for pitch angle as pointing straight down instead of horizontally
  const fenceHeight = 2.5; // meters = ~8 ft
  // creates offset that aligns center of the camera to the middle of the fence
  const bufferDistance =
    0 - (altitude - fenceHeight / 2.0) * Math.tan(convertedPitchAngleRadian);
  const bufferedPolygon = buffer(polygon, bufferDistance, { units: 'meters' });

  if (!bufferedPolygon) {
    console.error(
      'Perimeter flight path could not be created. Try increasing the size of the polygon.',
    );
    return;
  } else {
    const perimeterFlightLine:
      | Feature
      | FeatureCollection<LineString | MultiLineString> = truncate(
      polygonToLine(bufferedPolygon),
      { precision: 10 },
    );
    return perimeterFlightLine;
  }
}

// Calculate flight time based on length of flight path and flight speed.
// TODO: add distance to and from dock to calculation
export function calculateFlightTimeFromGridline(
  gridLine,
  flightSpeed: number,
  flightMode: CombinedFlightModeType,
): number {
  let flightTimeSec = 0;
  if (gridLine && Object.keys(gridLine).length > 0) {
    const flightDistance = lineDistance(gridLine) * 1000;
    flightTimeSec = flightDistance / flightSpeed;
  }
  if (flightMode == FlightModeType.Iimode) {
    // Multiply flight time by 3 to account for hover and capture
    flightTimeSec = flightTimeSec * 3;
  }
  return flightTimeSec;
}

export function createWaypointsFromFlightPath(
  flightPathLine: Feature<LineString>,
  altitude: number,
  fieldOfViewHorizontal: number,
  frontOverlap: number,
  flightAngle: number,
): FeatureCollection<Point> {
  const waypoints: Feature<Point>[] = [];
  const distanceSpacing =
    getFieldOfViewSpacing(altitude, fieldOfViewHorizontal, frontOverlap) /
    1000.0; // km

  // Variable for testing
  /* flightPathLine = {
   *   "type": "Feature",
   *   "properties": {},
   *   "geometry": {
   *     "type": "LineString",
   *     "coordinates": [
   *       [
   *               -70.91538308740459,
   *         42.42379498368541
   *       ],
   *       [
   *               -70.91173354504237,
   *         42.422895663321704
   *       ],
   *       [
   *               -70.91568654048756,
   *         42.422895663321704
   *       ],
   *       [
   *               -70.91598999357053,
   *         42.42199634295796
   *       ],
   *       [
   *               -70.9118308280557,
   *         42.42199634295796
   *       ],
   *       [
   *               -70.91192811106902,
   *         42.42109702259424
   *       ],
   *       [
   *               -70.9162934466535,
   *         42.42109702259424
   *       ]
   *     ]
   *   }
   * } */

  // calculate arc length
  const segments = lineChunk(flightPathLine, distanceSpacing, {
    units: 'kilometers',
  });

  segments.features.forEach((line, idx, array) => {
    waypoints.push(
      point(line.geometry.coordinates[0], {
        name: idx.toString(),
        icon: 'circle',
        rotation: flightAngle,
      }),
    );

    // appends the very last point
    if (idx === array.length - 1) {
      waypoints.push(
        point(line.geometry.coordinates[1], {
          name: (idx + 1).toString(),
          icon: 'circle',
          rotation: flightAngle,
        }),
      );
    }
  });

  const fc = featureCollection(waypoints);

  return fc;
}

export function createWaypointsFromPerimeterFlightPath(
  flightPathLine: Feature<LineString>,
  altitude: number,
  fieldOfViewHorizontal: number,
  frontOverlap: number,
): FeatureCollection<Point> {
  const waypoints: Feature<Point>[] = [];
  const distanceSpacing = getFieldOfViewSpacing(
    altitude,
    fieldOfViewHorizontal,
    frontOverlap,
  ); // meters

  const segments = lineChunk(flightPathLine, distanceSpacing, {
    units: 'meters',
  });
  segments.features.forEach((line, idx, array) => {
    const localHeading =
      bearing(line.geometry.coordinates[0], line.geometry.coordinates[1]) + 90;
    waypoints.push(
      point(line.geometry.coordinates[0], {
        name: idx.toString(),
        icon: 'circle',
        rotation: localHeading,
      }),
    );

    // appends the very last point
    if (idx === array.length - 1) {
      waypoints.push(
        point(line.geometry.coordinates[1], {
          name: (idx + 1).toString(),
          icon: 'circle',
          rotation: localHeading,
        }),
      );
    }
  });

  const fc = featureCollection(waypoints);

  return fc;
}

function isClockwise(polygon): boolean {
  // Use shoelace to determine whether the order of points is clockwise
  const coordinates =
    polygon.type === 'Feature'
      ? polygon.geometry.coordinates[0]
      : polygon.coordinates[0];
  let sum = 0;

  for (let i = 0; i < coordinates.length - 1; i++) {
    const [x1, y1] = coordinates[i];
    const [x2, y2] = coordinates[i + 1];
    sum += (x2 - x1) * (y2 + y1);
  }

  return sum > 0;
}

export function createSquareOrbitalLines(
  polygon: Feature<Polygon>,
  altitude: number,
  verticalFOV: number,
  gimbalPitchAngle: number,
  horizontalFOV: number,
): Feature<LineString> {
  // Find offset that drone should be from polygon. Assumes polygon outline is at bottom edge of image
  let offset =
    Math.tan(degreesToRadians(90 + gimbalPitchAngle - (1 / 2) * verticalFOV)) *
    altitude;
  // prevent negative offsets as the logic doesn't exist to handle the corners
  if (offset < 0) {
    offset = 0;
  }

  const lineCoordinates = [];

  // Make a copy of the coordinates, removing duplicate from end, rotate if needed so always clockwise to make angle math work
  const cornerCoordinates = polygon.geometry.coordinates[0].slice(0, -1);
  if (!isClockwise(polygon)) {
    cornerCoordinates.reverse(); // Reverse the copy
  }
  const lengthCoordinateArray = cornerCoordinates.length;

  // Itterate through coordinates
  cornerCoordinates.forEach((cornerCoordinate: Position, index) => {
    // Calculate bearing to the next point (wrap around to start if at end)
    const nextIndex = (index + 1) % lengthCoordinateArray;
    const nextPoint = point(cornerCoordinates[nextIndex]);
    const bearingToNext = bearing(cornerCoordinate, nextPoint);

    // Calculate bearing to the previous point (wrap around to end if at start)
    const prevIndex =
      (index - 1 + lengthCoordinateArray) % lengthCoordinateArray;
    const previousPoint = point(cornerCoordinates[prevIndex]);
    const bearingToPrevious = bearing(cornerCoordinate, previousPoint);

    // Compare bearings
    const angleBetween = bearingToNext - bearingToPrevious;
    const normalizedAngleBetween = ((angleBetween % 360) + 360) % 360;

    if (normalizedAngleBetween > 180) {
      // If angle > 180 add points to wrap around corner
      // number of sections based on field of view
      const numberOfSections = Math.ceil(
        (normalizedAngleBetween - 180) / horizontalFOV,
      );
      const angleToMove = (normalizedAngleBetween - 180) / numberOfSections;
      const offsetInKM = offset / 1000; // m -> km
      // first point is perpendicular to bearing to previous by offset
      let bearingToNewPoint = bearingToPrevious + 90;

      // find all the new coordinates and add them
      for (let i = 0; i <= numberOfSections; i++) {
        const newPoint = destination(
          cornerCoordinate,
          offsetInKM,
          bearingToNewPoint,
        );
        lineCoordinates.push(newPoint.geometry.coordinates);
        bearingToNewPoint += angleToMove;
      }
    } else if (normalizedAngleBetween < 180) {
      // If angle < 180 find point at which camera should rotate in place
      const distanceFromCorner =
        offset / Math.sin(degreesToRadians(normalizedAngleBetween / 2));
      const rotationPosition = destination(
        cornerCoordinate,
        distanceFromCorner / 1000,
        bearingToPrevious + normalizedAngleBetween / 2,
      );
      lineCoordinates.push(rotationPosition.geometry.coordinates);
    }
  });

  // add first point to end to complete loop
  lineCoordinates.push(lineCoordinates[0]);
  const wayline = lineString(lineCoordinates, { name: 'Wayline' });
  return wayline;
}
export function createSquareOrbitalPoints(
  line: Feature<LineString>,
  horizontalOverlap: number,
  altitude: number,
  horizontalFOV: number,
  cameraInterval: number,
  flightSpeed: number,
): FeatureCollection<Point> {
  // Get first set of points from corners of Wayline
  const horizontalSpacing = getFieldOfViewSpacing(
    altitude,
    horizontalFOV,
    horizontalOverlap,
  );
  const intermediateWaypoints: Feature<Point>[] = divideLineSegmentsIntoPoints(
    line,
    horizontalSpacing,
  );

  // Itterate throught new set of points, add flightSpeed, bearing, name and add extra points at inner corners
  const waypoints: Feature<Point>[] = [];
  let waypointCount = 0;
  const lengthWaypointArray = intermediateWaypoints.length;

  intermediateWaypoints.forEach((waypoint, index) => {
    waypoint.properties.name = waypointCount;

    // Setup previous and next waypoints
    const prevIndex = (index - 1 + lengthWaypointArray) % lengthWaypointArray;
    const previousPoint = intermediateWaypoints[prevIndex];
    const nextIndex = (index + 1) % lengthWaypointArray;
    const nextPoint = intermediateWaypoints[nextIndex];

    // Calculate speed to next waypoint
    // Speed at each point should be based on camera interval and distance to next waypoint
    const waypointFlightSpeed = calculateWaypointFlightSpeed(
      waypoint,
      nextPoint,
      cameraInterval,
      flightSpeed,
    );
    waypoint.properties.flightSpeed = waypointFlightSpeed;

    // Compare bearings to find angle at current waypoint
    const bearingToNext = bearing(waypoint, nextPoint);
    const bearingToPrevious = bearing(waypoint, previousPoint);
    const angleBetween = bearingToNext - bearingToPrevious;
    const normalizedAngleBetween = ((angleBetween % 360) + 360) % 360;
    if (normalizedAngleBetween == 0) {
      console.error(`normalized angle = 0`);
    }

    // Add bearings to waypoints, add extra waypoints as needed for full coverage
    if (Math.round(normalizedAngleBetween) < 180) {
      // If bearing is smaller than 180 it is an inner point and needs extra waypoints added for full coverage
      // Add bearing to first waypoint at corner to align with previous line segment
      let waypointBearing = bearingToPrevious - 90;
      if (waypointBearing < -180) {
        waypointBearing += 360;
      }
      waypoint.properties.rotation = waypointBearing;
      waypoints.push(waypoint);
      waypointCount += 1;

      // check if more than two waypoints are needed at this location by checking if the angle remaining between the two waypoint's headings is more than is covered by the FOV, taking into account desired overlap between images
      const remainingAngle = 180 - normalizedAngleBetween;
      const overlapNumber = horizontalFOV * horizontalOverlap;
      const segmentsToAdd = Math.ceil(
        remainingAngle / (horizontalFOV - overlapNumber),
      );
      const angleToRotateBy = remainingAngle / segmentsToAdd;

      for (let i = 1; i <= segmentsToAdd; i++) {
        let waypointBearing = bearingToPrevious - 90 - angleToRotateBy * i;
        if (waypointBearing < -180) {
          waypointBearing += 360;
        }

        const intermediateWaypoint = point(waypoint.geometry.coordinates, {
          name: waypointCount,
          rotation: waypointBearing,
          hover: cameraInterval,
          flightSpeed: waypointFlightSpeed,
        });
        waypoints.push(intermediateWaypoint);
        waypointCount += 1;
      }

      // add bearing to last waypoint at this corner to align with next line segment
      const finalBearingAtThisLocation = bearingToNext + 90;
      const newWaypoint = point(waypoint.geometry.coordinates, {
        name: waypointCount,
        rotation: finalBearingAtThisLocation,
        hover: cameraInterval,
        flightSpeed: waypointFlightSpeed,
      });
      waypoints.push(newWaypoint);
    } else {
      // for all other points bearing should be half bearing between previous and next
      const waypointBearing =
        bearingToNext + (360 - normalizedAngleBetween) / 2;
      waypoint.properties.rotation = waypointBearing;
      waypoints.push(waypoint);
    }

    waypointCount += 1;
  });
  return featureCollection(waypoints);
}

function divideLineSegmentsIntoPoints(line, spacing) {
  const coordinates = line.geometry.coordinates;
  const lengthCoordinateArray = coordinates.length;
  const waypoints: Feature<Point>[] = [];
  coordinates.forEach((coordinate: Position, index) => {
    // Find length between this and next coordinate
    // If length is greater than horizontal spacing divide into even chunks no longer than spacing
    const nextIndex = (index + 1) % lengthCoordinateArray;
    const lineSegment = lineString([
      coordinate,
      point(coordinates[nextIndex]).geometry.coordinates,
    ]);
    const lineSegmentLength = length(lineSegment) * 1000; // km-> m

    const numberOfSegments = Math.ceil(lineSegmentLength / spacing);
    const distanceBetweenWaypoints = lineSegmentLength / numberOfSegments;
    let progressAlongLine = 0;

    for (let i = 0; i < numberOfSegments; i++) {
      const myNewPoint = along(lineSegment, progressAlongLine / 1000);
      waypoints.push(myNewPoint);
      progressAlongLine += distanceBetweenWaypoints;
    }
  });
  return waypoints;
}

function calculateWaypointFlightSpeed(
  waypoint,
  nextPoint,
  cameraInterval,
  maxSpeed,
) {
  const distanceToNextWaypoint = distance(waypoint, nextPoint) * 1000; // km->m
  const flightSpeedToNextWaypoint = distanceToNextWaypoint / cameraInterval;
  const waypointFlightSpeed =
    maxSpeed < flightSpeedToNextWaypoint ? maxSpeed : flightSpeedToNextWaypoint;
  return waypointFlightSpeed;
}
