import {
  DeviceModelResponseLite,
  DeviceResponse,
} from '@raptormaps/raptor-flight-client-ts';

export interface PropertyDisplay {
  description: string;
  label: string;
}

export enum AircraftChargeState {
  Charging = 'charging',
  Standby = 'standby',
  Unknown = 'unknown',
}

export enum DockState {
  FirmwareUpdateInProgress = 'firmware_upgrade_in_progress',
  Idle = 'idle',
  InOperation = 'in_operation',
  OnSiteDebugging = 'on_site_debugging',
  RemoteDebugging = 'remote_debugging',
  Unknown = 'unknown',
}

export enum AircraftState {
  Standby = 'standby',
  TakeoffPreparation = 'takeoff_preparation',
  Manual = 'manual_flight',
  Takeoff = 'takeoff',
  Operating = 'operating',
  ObstacleAvoidance = 'obstacle_avoidance',
  AutoReturnHome = 'auto_returning_home',
  Landing = 'landing',
  ForcedLanding = 'forced_landing',
  Upgrading = 'upgrading',
  NotConnected = 'not_connected',
  AirborneRTKFixingMode = 'airborne_rtk_fixing_mode',
  DockAddressSelecting = 'dock_address_selecting',
  POI = 'poi',
  Unknown = 'unknown',
}

export enum DockCoverState {
  Abnormal = 'abnormal',
  HalfOpen = 'half_open',
  Closed = 'closed',
  Closing = 'closing',
  Open = 'open',
  Opening = 'opening',
  Error = 'error',
  Unknown = 'unknown',
  EStop = 'estop_active',
}

export enum AircraftOnlineState {
  Off = 'offline',
  On = 'online',
  Unknown = 'unknown',
}

export enum RainfallState {
  NoRain = 'no_rain',
  Light = 'light_rain',
  Moderate = 'moderate_rain',
  Heavy = 'heavy_rain',
}

export enum EmergencyStopState {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export interface DockDevice extends DeviceResponse {
  devices: DeviceResponse[];
  dockDroneModel?: DeviceModelResponseLite;
}
