//external dependancies
import { useMutation, useQueryClient } from 'react-query';
import {
  ResponseError,
  FlightsApi,
  FlightUploadResponse,
} from '@raptormaps/raptor-flight-client-ts';

// local dependancies
import { useApi } from './useApi';
import { useAppContext } from '../context/AppContext';

export const useCreateKmzUpload = (solarFarmId: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(FlightsApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  return useMutation<FlightUploadResponse, ResponseError, File>({
    mutationFn: async file => {
      return api.uploadKmzFlightV1FlightsKmzUploadPost({
        orgId,
        solarFarmId,
        file,
      });
    },
    onSuccess: result => {
      queryClient.setQueryData(
        ['kmz_upload', orgId, solarFarmId, result.fileUrl],
        result,
      );
    },
  });
};
