import { useState, useEffect, useMemo } from 'react';

// Components
import DockOverview from './Dock/DockOverview';
import SiteOverview from './Site/SiteOverview';
import SiteOverviewMap from './Map/SiteOverviewMap';
import DigitalTwinSection from '@/shared/components/DigitalTwin/DigitalTwin';
import BackgroundSection from '@/shared/components/Background/Background';

// Mapbox
import mapboxgl from 'mapbox-gl';

// Page Props
import { PageProps } from '@/shared/types/page';

// Styled Components
import {
  Divider,
  MapSidebarContainer,
  OverviewSidebarContainer,
  SidebarBottomPadding,
  SiteOverviewContainer,
} from './Overview.styled';
import { FloatingSidebarContainer, Sidebar } from '@raptormaps/sidebar';
import { Stack } from '@raptormaps/layout';
import { ThreeCircles } from 'react-loader-spinner';
import theme from '@raptormaps/theme';

// Hooks
import { useSolarFarmById } from '@/shared/hooks/useSolarFarms';
import { useToast } from '@raptormaps/toast';
import { useGetDevices } from '@/shared/hooks/useDevices';
import { useGetDockDeviceIds } from '@/shared/hooks/useDockDevices';
import { useNavigate, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import {
  TelemetryWebsocketState,
  useTelemetryStore,
} from '@/shared/stores/TelemetryWebsocket/TelemetryWebsocketStore';

// Types
import { DockDevice } from '@/shared/types/devices';
import { DockDeviceIdsResponse } from '@raptormaps/raptor-flight-client-ts';
import { Feature, Point } from '@turf/helpers';

const OverviewRouter = ({ solarFarmId }: PageProps) => {
  const [map, setMap] = useState<mapboxgl.Map>(null);
  const [activeRowId, setActiveRowId] = useState<number>(null);
  const [slider, setSlider] = useState<number>(25);

  const params = useParams();
  const selectedDockId = useMemo(() => params?.deviceId, [params]);

  const toast = useToast();
  const navigate = useNavigate();

  const handleSolarFarmError = () => {
    toast.error(`Error fetching solar farm with ID ${solarFarmId}`);
  };

  const unsubscribeFromDevices = useTelemetryStore(
    useShallow(
      (state: TelemetryWebsocketState) => state.actions.unsubscribeFromDevices,
    ),
  );

  const subscribeToDevices = useTelemetryStore(
    useShallow(
      (state: TelemetryWebsocketState) => state.actions.subscribeToDevices,
    ),
  );

  const subscribedDevices = useTelemetryStore(
    useShallow((state: TelemetryWebsocketState) => state.subscribedDevices),
  );
  const socketIsOpen = useTelemetryStore(
    useShallow((state: TelemetryWebsocketState) => state.socketIsOpen),
  );

  const {
    data: solarFarm,
    isLoading: solarFarmLoading,
    error: solarFarmError,
  } = useSolarFarmById(solarFarmId, handleSolarFarmError);

  const { data: devices, isLoading: devicesLoading } = useGetDevices({
    solarFarmId,
  });

  const { data: dockDeviceIds, isLoading: dockDeviceIdsLoading } =
    useGetDockDeviceIds({ solarFarmId });

  const getDeviceFromDevices = () => {
    const deviceId = selectedDockId;

    const selectedDockDeviceId = dockDeviceIds.find(
      (dockDeviceId: DockDeviceIdsResponse) =>
        dockDeviceId.dockId === Number(deviceId),
    );

    const dock = devices?.solarFarmDevices?.find(
      device => device.id === selectedDockDeviceId.dockId,
    );
    const dockDevices = devices?.solarFarmDevices?.filter(device =>
      selectedDockDeviceId.deviceIds.includes(device.id),
    );
    return { ...dock, devices: dockDevices };
  };

  const dockDevice: DockDevice = useMemo(() => {
    if (
      selectedDockId &&
      dockDeviceIds?.length > 0 &&
      devices?.solarFarmDevices?.length > 0
    ) {
      return getDeviceFromDevices();
    }

    return null;
  }, [dockDeviceIds, devices, params, selectedDockId]);

  useEffect(() => {
    if (selectedDockId && dockDevice) {
      // If user navigates directly to view, subscribe to dock
      const subscribedDeviceSns = subscribedDevices.map(
        device => device.deviceSn,
      );
      if (
        dockDevice &&
        socketIsOpen &&
        !subscribedDeviceSns.includes(dockDevice.deviceSn)
      ) {
        subscribeToDevices([dockDevice], solarFarmId);
      }
    }
  }, [selectedDockId, dockDevice, socketIsOpen, dockDeviceIds, devices]);

  const navigateToDock = (
    dockDevice: DockDevice,
    dockLocation: Feature<Point>,
  ) => {
    unsubscribeFromNonSelectedDevices(dockDevice);
    navigate({
      search: `?solar_farm_id=${solarFarmId}`,
      pathname: `/overview/${dockDevice.id}`,
    });
    navigateToDockLocation(dockLocation);
  };

  const navigateToSite = () => {
    navigate({
      search: `?solar_farm_id=${solarFarmId}`,
      pathname: `/overview`,
    });
  };
  const unsubscribeFromNonSelectedDevices = (dockDevice: DockDevice) => {
    const dockIds = dockDeviceIds.map(dockDeviceId => dockDeviceId.dockId);
    const unselectedDeviceIds = dockIds.filter(id => id !== dockDevice.id);
    unsubscribeFromDevices(unselectedDeviceIds, solarFarm.id);
  };

  const navigateToDroneLocation = droneLocation => {
    if (map && droneLocation) {
      map.flyTo({
        center: [
          droneLocation.geometry.coordinates[0],
          droneLocation.geometry.coordinates[1],
        ],
        offset: [300, 0],
      });
    }
  };
  const navigateToDockLocation = dockLocation => {
    if (map && dockLocation) {
      map.flyTo({
        center: [
          dockLocation.geometry.coordinates[0],
          dockLocation.geometry.coordinates[1],
        ],
        offset: [300, 0],
      });
    }
  };

  if (devicesLoading || solarFarmLoading || dockDeviceIdsLoading) {
    return (
      <>
        <FloatingSidebarContainer>
          <Sidebar title="Site Overview" defaultOpen>
            <Divider />
            <SiteOverviewContainer>
              <Stack align="center" style={{ marginTop: '5%' }}>
                <ThreeCircles
                  height="40"
                  width="40"
                  color={theme.colors.primary_400}
                  visible={true}
                  ariaLabel="loading spinner"
                />
              </Stack>
            </SiteOverviewContainer>
          </Sidebar>
        </FloatingSidebarContainer>
      </>
    );
  }

  if (solarFarmError || !solarFarm) {
    return (
      <>
        <OverviewSidebarContainer>
          <Sidebar title="Site Overview" defaultOpen>
            <Divider />
            <SiteOverviewContainer>
              <Stack align="center"></Stack>
            </SiteOverviewContainer>
          </Sidebar>
        </OverviewSidebarContainer>
      </>
    );
  }

  return (
    <>
      <OverviewSidebarContainer>
        <Sidebar
          title={
            !selectedDockId
              ? `Site Overview: ${solarFarm?.name}`
              : `${dockDevice?.deviceName} Overview`
          }
          defaultOpen
        >
          {!selectedDockId ? (
            <SiteOverview
              navigateToDock={navigateToDock}
              devices={devices.solarFarmDevices}
              dockDeviceIds={dockDeviceIds}
            />
          ) : (
            <DockOverview
              navigateToSite={navigateToSite}
              dockDevice={dockDevice}
              navigateToDroneLocation={navigateToDroneLocation}
            />
          )}
          <MapSidebarContainer>
            <DigitalTwinSection
              solarFarmId={solarFarmId}
              map={map}
              activeRowId={activeRowId}
            />
            <BackgroundSection
              map={map}
              solarFarmId={solarFarmId}
              slider={slider}
              setSlider={setSlider}
              useDroneIcon={false}
            />

            <SidebarBottomPadding />
          </MapSidebarContainer>
        </Sidebar>
      </OverviewSidebarContainer>
      <SiteOverviewMap
        map={map}
        setMap={setMap}
        setActiveRowId={setActiveRowId}
        activeRowId={activeRowId}
        solarFarm={solarFarm}
      />
    </>
  );
};

export default OverviewRouter;
