import theme from '@raptormaps/theme';
import styled from 'styled-components';

export const VIDEO_WIDTH = 496;
export const VIDEO_HEIGHT = 279;

export const LiveSreamViewerModalContainer = styled.div`
  position: fixed;
  top: 24px;
  right: 24px;
  width: ${VIDEO_WIDTH}px;
  z-index: 1;
  border-radius: 12px;
  box-shadow:
    rgba(16, 24, 40, 0.1) 0px 1px 2px -1px,
    rgba(16, 24, 40, 0.1) 0px 1px 3px 0px;
`;

export const ViewerBackgroundContainer = styled.div`
  background-color: ${theme.colors.black};
`;
