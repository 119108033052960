/* This contains all the URLs and names from Mapbox to enable the various overlays to be displayed */
import {
  SourceId,
  ROWS_SOURCE_ID,
  CAB_LINES_SOURCE_ID,
  CAB_COMBINERS_SOURCE_ID,
  INVERTERS_SOURCE_ID,
  INVERTER_BLOCKS_SOURCE_ID,
  INVERTER_BLOCKS_LABEL_SOURCE_ID,
  AS_BUILT_SOURCE_ID,
  ROAD_SOURCE_ID,
} from './sources/TilesetSources';

export type SolarFarmMapBox = {
  id: number;
  longitude?: number;
  latitude?: number;
  sources: {
    [key in SourceId]?: {
      tilesetUrl: string;
      vectorLayer: string;
      label?: string;
    };
  };
};

type SolarFarmById = {
  [key: string]: SolarFarmMapBox;
};

export const azureSky: SolarFarmMapBox = {
  id: 6988,
  longitude: -99.6162892,
  latitude: 33.0038905,
  sources: {
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.ceve9die',
      vectorLayer: 'azure_sky_pvrow',
      label: 'id',
    },
    [INVERTER_BLOCKS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.84wa9say',
      vectorLayer: 'azure_sky_inverter_block',
    },
  },
};

export const centralLine: SolarFarmMapBox = {
  id: 13448,
  longitude: -111.569,
  latitude: 32.832,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.b32eryny',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.17z833nw',
      vectorLayer: 'CentralLineRows-5k20tv',
    },
    [CAB_COMBINERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.438sh20z',
      vectorLayer: 'CentralLineCombiners-6i67gw',
    },
    [INVERTERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.3h0lm4ho',
      vectorLayer: 'CentralLineInverters-9l3rjw',
    },
  },
};

export const cloverCreek: SolarFarmMapBox = {
  id: 12784,
  longitude: -111.887438,
  latitude: 39.84162,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.7h90kg9k',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.2z79bgkz',
      vectorLayer: '2023-06-21_clover_creek-0amix4',
    },
    [INVERTERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.1u12j4s0',
      vectorLayer: 'clover_inverters-2rgznk',
    },
  },
};

export const corazon: SolarFarmMapBox = {
  id: 18726,
  longitude: -99.2287039,
  latitude: 27.659227,
  sources: {
    [INVERTER_BLOCKS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.7rh9smtx',
      vectorLayer: 'Corazon_USGS_Boundary.kmz',
    },
  },
};

export const davis: SolarFarmMapBox = {
  id: 22189,
  longitude: -71.399793,
  latitude: 42.416742,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.7yujbj13',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
  },
};

export const eastLine: SolarFarmMapBox = {
  id: 13447,
  longitude: -111.568,
  latitude: 32.846,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.451fp4pc',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.23f50dsd',
      vectorLayer: 'EastLineRows-8osadq',
    },
    [CAB_COMBINERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.drqnjg2l',
      vectorLayer: 'EastLineCombiners-786yzl',
    },
    [INVERTERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.4ubvhiv2',
      vectorLayer: 'EastLineInverters-0mcws3',
    },
  },
};

export const hickoryPark: SolarFarmMapBox = {
  id: 22618,
  sources: {
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.0qqgibdn',
      vectorLayer: 'Rows',
    },
    [INVERTER_BLOCKS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.0qqgibdn',
      vectorLayer: 'Blocks',
    },
    [INVERTER_BLOCKS_LABEL_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.0qqgibdn',
      vectorLayer: 'Block_Labels',
    },
    [ROAD_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.0qqgibdn',
      vectorLayer: 'Roads',
    },
  },
};

export const highlander: SolarFarmMapBox = {
  id: 13446,
  longitude: -77.7344,
  latitude: 38.2433,
  sources: {
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.1okjhnpo',
      vectorLayer: '2023-07-19_highlander-0vdnsd',
    },
    [CAB_LINES_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.4rh76voc',
      vectorLayer: 'highlander_cablines_test_5-di9ena',
    },
    [CAB_COMBINERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.4kuh08x6',
      vectorLayer: 'highlander_combiners-72qyzq',
    },
    [INVERTERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.c77pekqj',
      vectorLayer: '2023-07-19_highlander_inverte-b9u7ip',
    },
  },
};

export const huntley: SolarFarmMapBox = {
  id: 6731,
  sources: {
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.3zym1o5o',
      vectorLayer: 'huntley-afqwef',
    },
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.1w3z9295',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
  },
};

export const ii145NBeaconStreet: SolarFarmMapBox = {
  id: 22190,
  longitude: -71.144806,
  latitude: 42.355222,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.7ac50s2k',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.avh8m6g5',
      vectorLayer: 'outline',
    },
  },
};

export const ii225Arlington: SolarFarmMapBox = {
  id: 9976,
  longitude: -71.416015,
  latitude: 42.26819,
  sources: {
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.1o6e4lr5',
      vectorLayer: 'ii__225_arlington',
    },
  },
};

export const iiCPIConnector: SolarFarmMapBox = {
  id: 19886,
  longitude: -74.973,
  latitude: 40.011,
  sources: {
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.8gl1816c',
      vectorLayer: 'CPIConnectorRows-83egi6',
    },
  },
};

export const iiJCPenney: SolarFarmMapBox = {
  id: 19885,
  longitude: -75.026,
  latitude: 39.944,
  sources: {
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.9qej8op1',
      vectorLayer: 'JCPenney_Rows-966ss2',
    },
  },
};

export const iiMannington1: SolarFarmMapBox = {
  id: 19883,
  longitude: -75.454,
  latitude: 39.581,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.4acsd9m1',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.c8yvufh3',
      vectorLayer: 'Mannington1_Rows-2cmasd',
    },
  },
};

export const iiMannington2: SolarFarmMapBox = {
  id: 19884,
  longitude: -75.453,
  latitude: 39.579,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.2z48cpc8',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.32zlzvzt',
      vectorLayer: 'Mannington2_Rows-5ssqq5',
    },
  },
};

export const iiRaymourAndFlanigan: SolarFarmMapBox = {
  id: 19887,
  longitude: -75.266,
  latitude: 39.811,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.1u4gw8bk',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.a07sz2or',
      vectorLayer: 'RaymournFlanigan_Rows-dsh9j9',
    },
  },
};

export const iiTransportCenter: SolarFarmMapBox = {
  id: 19888,
  longitude: -74.808,
  latitude: 39.895,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.dbfmx1s9',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.b5qvequ7',
      vectorLayer: 'TransportCenter_Rows-brslu4',
    },
  },
};

export const iiUSDoT: SolarFarmMapBox = {
  id: 19889,
  longitude: -74.818,
  latitude: 39.929,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.d5evdi7l',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.c5ahr8df',
      vectorLayer: 'USDOT_Rows-9wag4h',
    },
  },
};

export const juno: SolarFarmMapBox = {
  id: 15985,
  longitude: -101.625,
  latitude: 32.779,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.dtieam0b',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.77euwd3p',
      vectorLayer: 'Juno_rows-5ygg2q',
    },
    [CAB_COMBINERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.dn954eco',
      vectorLayer: 'Juno_combiners-d7diqc',
    },
    [INVERTERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.2e4hbk61',
      vectorLayer: 'Juno_inverters-43ej91',
    },
  },
};

export const lily: SolarFarmMapBox = {
  id: 16348,
  longitude: -96.4226255034576,
  latitude: 32.534365787086,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.agxi5b3z',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.9nw9fdl8',
      vectorLayer: 'SolarPVRow',
    },
    [INVERTER_BLOCKS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.5dc8b4pd',
      vectorLayer: 'inv_blocks_SpatialJoin',
    },
  },
};

export const lumina1: SolarFarmMapBox = {
  id: 12181,
  latitude: 32.925,
  longitude: -100.974,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.atf0oxhv',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.89fn4m9g',
      vectorLayer: 'lumina1rows-0nk9vw',
    },
  },
};

export const radian: SolarFarmMapBox = {
  id: 19054,
  longitude: -99.192,
  latitude: 31.571,
  sources: {
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.8ysx1ius',
      vectorLayer: 'Radian_rows-05u660',
    },
  },
};

export const sunvalley: SolarFarmMapBox = {
  id: 19912,
  longitude: -97.0133,
  latitude: 31.8528,
  sources: {
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.cpzpgru3',
      vectorLayer: 'Trackers',
      label: 'Name',
    },
    [INVERTER_BLOCKS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.cpzpgru3',
      vectorLayer: 'Block_Boundaries',
    },
  },
};

export const titan: SolarFarmMapBox = {
  id: 17932,
  longitude: -104.459,
  latitude: 31.686,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.btb1vay0',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.8oxa46f3',
      vectorLayer: 'TitanRows-26yg1k',
    },
    [CAB_COMBINERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.cqsefnk0',
      vectorLayer: 'TitanCombiners-4x46tl',
    },
    [INVERTERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.44xp915q',
      vectorLayer: 'TitanInverters-0xput0',
    },
  },
};

export const troy: SolarFarmMapBox = {
  id: 14236,
  longitude: -86.79,
  latitude: 38.02,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.9joe3gca',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.8xivycfy',
      vectorLayer: 'troyrows-751k14',
    },
    [CAB_COMBINERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.b9u0zt2x',
      vectorLayer: 'troycombiners-9ez3b5',
    },
    [INVERTERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.3wrxc6mn',
      vectorLayer: 'troyinverters-8w4jy4',
    },
  },
};

export const wagyu: SolarFarmMapBox = {
  id: 8618,
  longitude: -95.656089,
  latitude: 29.243985,
  sources: {
    [INVERTER_BLOCKS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.btqghr0d',
      vectorLayer: 'wagyu_blocks',
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.7yqo4afx',
      vectorLayer: 'Wagyu_rows',
    },
  },
};

export const westLine: SolarFarmMapBox = {
  id: 13449,
  longitude: -111.591,
  latitude: 32.829,
  sources: {
    [AS_BUILT_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.7sbv3dzz',
      vectorLayer: AS_BUILT_SOURCE_ID,
    },
    [ROWS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.3j05kfsi',
      vectorLayer: 'WestLineRows-7p3wnr',
    },
    [CAB_COMBINERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.4qnsrexl',
      vectorLayer: 'WestLineCombiners-dw1btp',
    },
    [INVERTERS_SOURCE_ID]: {
      tilesetUrl: 'mapbox://raptormaps.a1r5nduf',
      vectorLayer: 'WestLineInverters-0fk357',
    },
  },
};

export const getSolarFarmById: SolarFarmById = {
  '6988': azureSky,
  '13448': centralLine,
  '12784': cloverCreek,
  '18726': corazon,
  '22189': davis,
  '13447': eastLine,
  '22618': hickoryPark,
  '13446': highlander,
  '6731': huntley,
  '22190': ii145NBeaconStreet,
  '9976': ii225Arlington,
  '19886': iiCPIConnector,
  '19885': iiJCPenney,
  '19883': iiMannington1,
  '19884': iiMannington2,
  '19887': iiRaymourAndFlanigan,
  '19888': iiTransportCenter,
  '19889': iiUSDoT,
  '15985': juno,
  '16348': lily,
  '9509': lumina1,
  '19054': radian,
  '19912': sunvalley,
  '17932': titan,
  '14236': troy,
  '8618': wagyu,
  '13449': westLine,
};
