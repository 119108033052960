//React
import { useMemo } from 'react';
import { FormikProps } from 'formik';

// Feature Flags
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';

// RaptorUI
import { DropdownMenu, DropdownOption } from '@raptormaps/dropdown-menu';
import { Icon } from '@raptormaps/icon';
import { Row } from '@raptormaps/layout';
import { CheckboxVariant } from '@raptormaps/checkbox/build/Checkbox.interface';
import { Tooltip } from '@raptormaps/tooltip';

// Styled Components
import {
  GridColumnOneSpanTwo,
  QuarterWidthTextInput,
  InlineQuartWidthTextInput,
  FullWidthGridSection,
  GridTextWithElipsis,
  FormLabel,
} from '../../../../MissionParametersSection.styles';
import { DropdownButton } from '@/shared/styles/input.styles';

// Constants
import {
  NUMBER_INPUT_STEP,
  PERCENT_INPUT_STEP,
  DRONE_CAMERA_DEFAULTS,
} from '@/pages/MissionPlanner/missionConstants';

// Types
import { CAMERA_TYPE_TO_DISPLAY_NAME } from '@/shared/constants/missionLookups';
import {
  FormParameters,
  FlightPlanningFormikValues,
} from '@/shared/types/missions';

// Utils
import { calculateControlledOverlap } from './cameraController.utils';
import {
  InlineCheckboxText,
  LargeCheckbox,
} from '../../AdvancedSettingsSection.styles';
import { CameraType } from '@raptormaps/raptor-flight-client-ts';

interface CameraControllerProps {
  formik: FormikProps<FlightPlanningFormikValues>;
  setDisplayFrontOverlap: (frontOverlap: number) => void;
  setDisplaySideOverlap: (sideOverlap: number) => void;
  displayFrontOverlap: number;
  displaySideOverlap: number;
  handleCalculateWaypoints: () => void;
  handleEnterPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  formParameters: FormParameters;
  handleInputScroll: (e: React.WheelEvent<HTMLInputElement>) => void;
  handleDroneCameraTypeUpdated: (drone: string, cameraType: CameraType) => void;
}

const CameraController = ({
  formik,
  setDisplayFrontOverlap,
  setDisplaySideOverlap,
  displayFrontOverlap,
  displaySideOverlap,
  handleCalculateWaypoints,
  handleEnterPress,
  formParameters,
  handleInputScroll,
  handleDroneCameraTypeUpdated,
}: CameraControllerProps) => {
  const showCameraSelectWithZoom = useFeatureIsOn(
    GrowthbookFlags.CAMERA_SELECT_WITH_ZOOM,
  );
  const { values, handleChange, errors } = formik;

  // Because we pass the values from the input to state so they can be converted to decimals before being passed to formik we need to handle the enter key press in the same manner.
  const handleEnterPressFrontOverlap = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === 'Enter') {
      setDisplayFrontOverlap(Number((e.target as HTMLInputElement).value));
      handleCalculateWaypoints();
    }
  };
  const handleEnterPressSideOverlap = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === 'Enter') {
      setDisplaySideOverlap(Number((e.target as HTMLInputElement).value));
      handleCalculateWaypoints();
    }
  };

  const controlledOverlap = useMemo(() => {
    const { controlledSideOverlap, controlledFrontOverlap } =
      calculateControlledOverlap(
        values.cameraType,
        values.drone,
        values.sideOverlap,
        values.frontOverlap,
      );
    return { side: controlledSideOverlap, front: controlledFrontOverlap };
  }, [values.cameraType, values.frontOverlap, values.sideOverlap]);

  const cameraTypeOptions: DropdownOption[] = Object.values(CameraType).map(
    (cameraType: CameraType) => ({
      onSelect: () => handleDroneCameraTypeUpdated(values.drone, cameraType),
      value: CAMERA_TYPE_TO_DISPLAY_NAME[cameraType],
      variant: 'option',
      style: { fontSize: '14px', justifyContent: 'start' },
    }),
  );

  return (
    <>
      {showCameraSelectWithZoom && (
        <>
          <FormLabel variant="label_small" style={{ marginBottom: '-15px' }}>
            Camera Type{' '}
          </FormLabel>
          {Object.values(CameraType).map((cameraType: CameraType, i) => (
            <FullWidthGridSection style={{ marginBottom: '-10px' }}>
              <Row style={{ gridColumn: '1 / span 2' }}>
                <LargeCheckbox
                  name=""
                  checked={values.cameraType === cameraType}
                  onChange={handleChange}
                  variant={CheckboxVariant.CHECKBOX}
                />
                <InlineCheckboxText variant={'paragraph_medium'}>
                  {cameraType}
                </InlineCheckboxText>
              </Row>
              <InlineQuartWidthTextInput
                label={i === 0 ? 'Zoom' : ''}
                style={{ gridColumn: '3 / span 2' }}
                inputSize="small"
                inputStyle="white-box"
                inputType="text"
                step={NUMBER_INPUT_STEP}
                value={'1x'}
                disabled={values.cameraType !== cameraType}
                onWheel={handleInputScroll}
                onChange={handleChange}
                onBlur={handleCalculateWaypoints}
                onKeyDownCapture={handleEnterPress}
                name="safeTakeoffAltitude"
              />
            </FullWidthGridSection>
          ))}
        </>
      )}

      <FullWidthGridSection
        style={
          errors.frontOverlap && errors.sideOverlap
            ? { marginBottom: '20px' }
            : null
        }
      >
        <GridColumnOneSpanTwo>
          <FormLabel
            title={
              'This input determines which camera FOV will be used to calculate the spacing between images to achieve the desired overlap.'
            }
            variant={'paragraph_small'}
            style={{ marginBottom: '5px' }}
          >
            {' '}
            Overlap Calculation
            <Tooltip
              colorVariant="dark"
              delay={100}
              placement={{
                align: 'center',
                side: 'right',
              }}
              tip="Select the active overlap calculation camera"
              shouldWrapChildren={true}
            >
              <Icon
                aria-label="Overlap Calculation Camera"
                icon="CircleInfo"
                size={'medium'}
                style={{ paddingBottom: '15px' }}
              />
            </Tooltip>
          </FormLabel>
          <DropdownMenu
            menuContentProps={{
              align: 'start',
              sideOffset: 0,
              side: 'bottom',
              style: {
                maxHeight: '70vh',
                width: '219px',
                minWidth: '120px',
                marginTop: '0px',
              },
            }}
            items={cameraTypeOptions}
            modal={false}
          >
            <DropdownButton
              style={{ width: '100%' }}
              disabled={false}
              size="small"
              variant="secondary"
            >
              {CAMERA_TYPE_TO_DISPLAY_NAME[values.cameraType]}
            </DropdownButton>
          </DropdownMenu>
        </GridColumnOneSpanTwo>
        <QuarterWidthTextInput
          style={{ marginTop: '1.5px' }}
          gridColumn={3}
          label={'Front (%)'}
          name="frontOverlap"
          inputSize="small"
          inputStyle="white-box"
          inputType="number"
          value={displayFrontOverlap}
          onWheel={handleInputScroll}
          onBlur={handleCalculateWaypoints}
          step={PERCENT_INPUT_STEP}
          min={formParameters.frontOverlapInput.min * 100}
          max={formParameters.frontOverlapInput.max * 100}
          onChange={e => setDisplayFrontOverlap(e.target.value)}
          onKeyDownCapture={handleEnterPressFrontOverlap}
          helperText={errors.frontOverlap}
          helperTextStyle={`left: -222px; top: 53px;`}
          className={`${errors.frontOverlap ? 'is-invalid' : ''}`}
        />
        <QuarterWidthTextInput
          style={{ marginTop: '1.5px' }}
          gridColumn={4}
          name="sideOverlap"
          label="Side (%)"
          inputSize="small"
          inputStyle="white-box"
          inputType="number"
          value={displaySideOverlap}
          onWheel={handleInputScroll}
          onBlur={handleCalculateWaypoints}
          step={PERCENT_INPUT_STEP}
          min={formParameters.sideOverlapInput.min * 100}
          max={formParameters.sideOverlapInput.max * 100}
          onChange={e => setDisplaySideOverlap(e.target.value)}
          onKeyDownCapture={handleEnterPressSideOverlap}
          helperText={errors.sideOverlap}
          helperTextStyle={`left: -334px; ${
            errors.frontOverlap && errors.sideOverlap
              ? 'top: 70px;'
              : 'top: 53px;'
          }`}
          className={`${errors.sideOverlap ? 'is-invalid' : ''}`}
        />
      </FullWidthGridSection>
      {Object.keys(DRONE_CAMERA_DEFAULTS[values.drone]).map(
        (cameraType: CameraType) => (
          <FullWidthGridSection style={{ marginTop: '-30px' }} key={cameraType}>
            <GridColumnOneSpanTwo>
              <GridTextWithElipsis>
                {' '}
                {CAMERA_TYPE_TO_DISPLAY_NAME[cameraType]}{' '}
              </GridTextWithElipsis>
            </GridColumnOneSpanTwo>
            <GridTextWithElipsis>
              {' '}
              {values.cameraType === cameraType
                ? Math.round(values.frontOverlap * 100)
                : Math.round(controlledOverlap.front * 100)}
              %{' '}
            </GridTextWithElipsis>
            <GridTextWithElipsis>
              {' '}
              {values.cameraType === cameraType
                ? Math.round(values.sideOverlap * 100)
                : Math.round(controlledOverlap.side * 100)}
              %{' '}
            </GridTextWithElipsis>
          </FullWidthGridSection>
        ),
      )}
    </>
  );
};

export default CameraController;
