import {
  AircraftChargeState,
  DockCoverState,
  AircraftOnlineState,
  RainfallState,
  AircraftState,
  EmergencyStopState,
} from '../types/devices.d';

// DJI Int to State Maps
// Source: https://developer.dji.com/doc/cloud-api-tutorial/en/api-reference/dock-to-cloud/mqtt/dock/dock2/properties.html
export const DJI_AIRCRAFT_CHARGE_TO_RM_CHARGE = {
  0: AircraftChargeState.Standby,
  1: AircraftChargeState.Charging,
};

export const DJI_DOCK_COVER_TO_RM_DOCK_COVER = {
  0: DockCoverState.Closed,
  1: DockCoverState.Open,
  2: DockCoverState.HalfOpen,
  3: DockCoverState.Abnormal,
};

export const DJI_AIRCRAFT_ONLINE_TO_RM_AIRCRAFT_ONLINE = {
  0: AircraftOnlineState.Off,
  1: AircraftOnlineState.On,
};

export const DJI_RAINFALL_TO_RM_RAINFALL = {
  0: RainfallState.NoRain,
  1: RainfallState.Light,
  2: RainfallState.Moderate,
  3: RainfallState.Heavy,
};

export const DJI_AIRCRAFT_STATE_TO_RM_STATE = {
  0: AircraftState.Standby,
  1: AircraftState.TakeoffPreparation,
  2: AircraftState.TakeoffPreparation,
  3: AircraftState.Manual,
  4: AircraftState.Takeoff,
  5: AircraftState.Operating,
  6: AircraftState.Operating,
  7: AircraftState.Operating,
  8: AircraftState.ObstacleAvoidance,
  9: AircraftState.AutoReturnHome,
  10: AircraftState.Landing,
  11: AircraftState.ForcedLanding,
  12: AircraftState.ForcedLanding,
  13: AircraftState.Upgrading,
  14: AircraftState.NotConnected,
  15: AircraftState.ObstacleAvoidance,
  16: AircraftState.Manual,
  17: AircraftState.Manual,
  18: AircraftState.AirborneRTKFixingMode,
  19: AircraftState.DockAddressSelecting,
  20: AircraftState.Operating,
};

export const DJI_EMERGENCY_STOP_STATE_TO_RM_EMERGENCY_STOP_STATE = {
  0: EmergencyStopState.Disabled,
  1: EmergencyStopState.Enabled,
};

export type DJI_MEMORY_CAPACITY_TYPE = {
  used: number;
  total: number;
};
