import { DJI_MEMORY_CAPACITY_TYPE } from '../constants/djiTelemetryConstants';

export const TELEMETRY_API_ENDPOINT =
  'wss://w7vf1gi886.execute-api.us-west-2.amazonaws.com/default/';

export interface TelemetryTextState {
  value: string;
  timestamp: number;
  isStale: boolean;
  lastMonitored: number;
}
export interface TelemetryNumberState {
  value: number;
  timestamp: number;
  isStale: boolean;
  lastMonitored: number;
}
export interface TelemetryNumberListState {
  value: number[];
  timestamp: number;
  isStale: boolean;
  lastMonitored: number;
}

export interface TelemetryStorageCapacityState {
  value: DJI_MEMORY_CAPACITY_TYPE;
  timestamp: number;
  isStale: boolean;
  lastMonitored: number;
}
export interface DockDeviceTelemetry {
  bid: TelemetryTextState;
  tid: TelemetryTextState;
  aircraftBatteryPercent: TelemetryNumberState;
  aircraftChargeState: TelemetryTextState;
  deviceSn: TelemetryTextState;
  windspeed: TelemetryNumberState;
  rainfall: TelemetryTextState;
  environmentTemperature: TelemetryNumberState;
  internetSpeed: TelemetryNumberState;
  aircraftDistanceFromDock: TelemetryNumberState;
  aircraftVerticalSpeed: TelemetryNumberState;
  aircraftHorizontalSpeed: TelemetryNumberState;
  aircraftState: TelemetryTextState;
  altitudeRelativeTakeoff: TelemetryNumberState;
  altitudeRelativeTerrain: TelemetryNumberState;
  aircraftRoll: TelemetryNumberState;
  aircraftPitch: TelemetryNumberState;
  longitude: TelemetryNumberState;
  latitude: TelemetryNumberState;
  coverState: TelemetryTextState;
  memoryCapacity: TelemetryStorageCapacityState;
  emergencyStopState: TelemetryTextState;
  remainingFlightTime: TelemetryNumberState;
  cameraPayloadIndex: TelemetryTextState;
  cameraGimbalPitch: TelemetryNumberState;
  cameraGimbalRoll: TelemetryNumberState;
  uploadedFileCount: TelemetryNumberState;
  expectedFileCount: TelemetryNumberState;
  aircraftHeading: TelemetryNumberState;
}

export interface TelemetryByDeviceSN {
  [key: string]: DockDeviceTelemetry; // key is the device serial number
}

export type TelemetryApiResponse = {
  body: string;
  statusCode: number;
};

export enum TelemetryApiRouteKey {
  subscribe = 'subscribe',
  unsubscribe = 'unsubscribe',
}

export interface TelemetryApiMessage {
  action: TelemetryApiRouteKey;
  solar_farm_id: number;
  gateway_sns: string[];
  Authorization: string;
}

type TelemetryValue = string | number;
