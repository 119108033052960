// External Dependencies
import { useState, useMemo, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormikProps } from 'formik';
import debounce from 'lodash/debounce';
// RaptorUI
import { Row, Gutter, Stack } from '@raptormaps/layout';
import { IconButton } from '@raptormaps/button';
import { DropdownMenu } from '@raptormaps/dropdown-menu';
import { TextInput } from '@raptormaps/text-input';
import theme from '@raptormaps/theme';

// Components
import { IconButtonAdornment } from '@/shared/styles/input.styles';
import SaveAsModal from './components/SaveAsModal';
import UniqueNameValidationMessage from './components/ValidationMessage';

//Types
import { FlightPlanningFormikValues } from '@/shared/types/missions';

// Hooks
import { useGetIsNameUnique, useGetMission } from '@/shared/hooks/useMissions';

interface MissionNameInputProps {
  formik: FormikProps<FlightPlanningFormikValues>;
  handleCreateMission: (missionName?: string | null) => void;
  saveAsOptionIsDisabled: boolean;
  solarFarmId: number;
}

const MissionNameInput = ({
  formik,
  handleCreateMission,
  saveAsOptionIsDisabled,
  solarFarmId,
}: MissionNameInputProps) => {
  const { values, handleChange, errors } = formik;
  const { missionId } = useParams();
  const [missionName, setMissionName] = useState(values.missionName);
  const handleMissionNameChange = useRef(
    debounce(e => {
      handleChange(e);
    }, 500),
  );
  const [openSaveAsModal, setOpenSaveAsModal] = useState<boolean>(false);
  const { data: current } = useGetMission(Number(missionId), solarFarmId);
  const { data: isNameUniqueData, isFetching: isFetchingIsUnique } =
    useGetIsNameUnique(values.missionName.trim(), solarFarmId);

  useEffect(() => {
    setMissionName(values.missionName);
  }, [values.missionName]);

  const isValidMissionName = useMemo(() => {
    return (
      /* A non-unique name is valid is it if from this mission unless saveAs is active */
      isNameUniqueData?.isUnique || values.missionName === current?.missionName
    );
  }, [isNameUniqueData, values.missionName, current]);

  const [disableMissionNameInput, setDisableMissionNameInput] =
    useState<boolean>(isValidMissionName && !!values.missionName);

  const missionNameRow = useRef(null);
  useEffect(() => {
    missionNameRow.current?.querySelector('input').focus();
  }, [disableMissionNameInput]);

  const errorText =
    values.missionName && !isValidMissionName
      ? 'Mission name must be unique. A Mission with this name already exists for this solar farm.'
      : errors.missionName;

  const handleSelectSaveAs = () => {
    setDisableMissionNameInput(true);
    setOpenSaveAsModal(true);
  };

  return (
    <Stack style={{ width: '100%', height: '78px' }}>
      <Row
        align="center"
        noWrap
        gutter={Gutter.XXL}
        style={{ width: '100%', textAlign: 'left' }}
      >
        <Row
          align="center"
          noWrap
          gutter={Gutter.M}
          style={{ width: '100%', textAlign: 'left' }}
          ref={missionNameRow}
        >
          <TextInput
            label="Mission Name"
            name="missionName"
            inputSize="small"
            placeholder="Mission Name"
            // this way the input will represent mission name that will be associated to the original mission if the users saves as with the new name
            value={missionName}
            disabled={disableMissionNameInput}
            onChange={e => {
              setMissionName(e.target.value);
              handleMissionNameChange.current(e);
            }}
            onBlur={e => {
              e.target.value = e.target.value.trim();
              setMissionName(e.target.value);
              handleMissionNameChange.current(e);
              // If there is a mission name and it is valid
              values.missionName && isValidMissionName
                ? setDisableMissionNameInput(true)
                : setDisableMissionNameInput(false);
            }}
            className={`${
              errors.missionName || !isValidMissionName ? 'is-invalid' : ''
            }`}
            style={{ width: '100%', color: theme.colors.black }}
          />
          {disableMissionNameInput && (
            <IconButtonAdornment
              aria-label="Rename Mission"
              icon={'Pen'}
              variant="tertiary"
              onClick={() => {
                setDisableMissionNameInput(false);
              }}
              onMouseDown={e => {
                e.preventDefault();
              }}
            />
          )}
        </Row>
        <DropdownMenu
          items={[
            {
              onSelect: () => {
                handleSelectSaveAs();
              },
              value: 'Save As',
              variant: 'option',
              disabled: saveAsOptionIsDisabled || !!errorText || !missionId,
            },
          ]}
          modal={false}
        >
          <IconButton
            aria-label="Open menu."
            icon="EllipsisVertical"
            variant="tertiary"
            style={{
              height: 40,
              display: 'flex',
              alignItems: 'flex-start',
              alignSelf: 'center',
              marginBottom: '-24px',
            }}
          />
        </DropdownMenu>
      </Row>
      {!openSaveAsModal && (
        <UniqueNameValidationMessage
          isFetching={isFetchingIsUnique}
          errorText={errorText}
        />
      )}

      <SaveAsModal
        solarFarmId={solarFarmId}
        currentName={values.missionName}
        setOpenSaveAsModal={setOpenSaveAsModal}
        openSaveAsModal={openSaveAsModal}
        handleCreateMission={handleCreateMission}
      />
    </Stack>
  );
};

export default MissionNameInput;
