// React
import { useState } from 'react';

// Mapbox
import { mapbox, Layers, Layer } from 'mapbox-gl';

// Components
import MapLayerSlider from '../../../pages/MissionPlanner/components/MissionPlanningForm/MissionPlanningSidebar/components/MapLayerSlider/MapLayerSlider';

// RaptorUI

import { Tooltip } from '@raptormaps/tooltip';

// Types
import { EquipmentLayerData } from './digitalTwin.d';
interface EquipmentSliderProps {
  map: mapbox.Map;
  equipmentLayerData: EquipmentLayerData;
  defaultOpacity?: number;
  disabled: boolean;
  disabledTooltipMessage: string;
}

const EquipmentSlider = ({
  map,
  equipmentLayerData,
  defaultOpacity,
  disabled,
  disabledTooltipMessage,
}: EquipmentSliderProps) => {
  const { name, layers } = equipmentLayerData;
  const [opacity, setOpacity] = useState(defaultOpacity ? defaultOpacity : 100);
  const [visible, setVisible] = useState(true);

  const handleChangeOpacity = (opacity: number, layers: Layers) => {
    layers.map((layer: Layer) => {
      if (layer.type === 'symbol') {
        map.setPaintProperty(layer.id, 'icon-opacity', opacity / 100);
        map.setPaintProperty(layer.id, 'text-opacity', opacity / 100);
      }
      if (layer.type === 'line') {
        map.setPaintProperty(layer.id, 'line-opacity', opacity / 100);
      }
      if (layer.type === 'fill') {
        map.setPaintProperty(layer.id, 'fill-opacity', opacity / 100);
      }
    });
    setOpacity(opacity);
  };

  const handleHideShowLayer = () => {
    layers.map((layer: Layer) => {
      if (map.getLayoutProperty(layer.id, 'visibility') != 'none') {
        map.setLayoutProperty(layer.id, 'visibility', 'none');
        setVisible(false);
      } else {
        map.setLayoutProperty(layer.id, 'visibility', 'visible');
        setVisible(true);
      }
    });
  };

  return disabled ? (
    <Tooltip
      colorVariant="dark"
      delay={100}
      placement={{
        align: 'center',
        side: 'right',
      }}
      tip={disabledTooltipMessage}
      shouldWrapChildren={true}
    >
      <MapLayerSlider
        name={name}
        opacity={opacity}
        layers={layers}
        visible={visible}
        disabled={disabled}
        handleChangeOpacity={handleChangeOpacity}
        handleHideShowLayer={handleHideShowLayer}
      />
    </Tooltip>
  ) : (
    <MapLayerSlider
      name={name}
      opacity={opacity}
      layers={layers}
      visible={visible}
      handleChangeOpacity={handleChangeOpacity}
      handleHideShowLayer={handleHideShowLayer}
    />
  );
};

export default EquipmentSlider;
