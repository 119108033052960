// External Dependencies
import { useState, useEffect, useMemo } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { useSearchParams } from 'react-router-dom';
import { UseFormReturn } from 'react-hook-form';
import { debounce } from 'lodash';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
// RaptorUI
import { Text } from '@raptormaps/text';
import { Button, IconButton } from '@raptormaps/button';
import { Row, Stack } from '@raptormaps/layout';
import { DropdownMenu } from '@raptormaps/dropdown-menu';
import { Tooltip } from '@raptormaps/tooltip';
import theme from '@raptormaps/theme';
import { DroneType, FlightModeType } from '@raptormaps/raptor-flight-client-ts';

// Internal Dependencies
import {
  useGetMissions,
  GET_MISSIONS_PAGINATION_LIMIT,
} from '@/shared/hooks/useMissions';
import {
  generateDroneOptions,
  generateFlightModeOptions,
} from '../../../../missionTaskUtils';

import {
  FLIGHT_MODE_TO_DISPLAY_NAME,
  DRONE_TYPE_TO_DISPLAY_NAME,
} from '@/shared/constants/missionLookups';
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';
import { useUrlSearchParams } from '@/shared/hooks/useUrlParams';
// Components
import { MissionCard } from '@/shared/components/MissionCard/MissionCard';
import {
  CardListContainer,
  SidebarBottomButtonContainer,
  StyledSearchBarRow,
  StyledSearchBarWithIcon,
  SearchBarIconButtonAdornment,
} from '../../CreateMissionTask.styles';
import {
  SidebarCloseButton,
  FullWidthRow,
} from '@/shared/styles/sidebar.styles';
import { MissionTaskPopovers } from '../../../../missionTask';
import { DropdownButton } from '@/shared/styles/input.styles';
import {
  MISSION_TASK_FORM_FIELDS,
  MISSION_LIST_QURERY_PARAMS,
} from '@/pages/MissionTasks/MissionTaskPlanner/missionTaskFormConstants';
import {
  CombinedFlightModeType,
  TemporaryFlightModeType,
} from '@/shared/types/tempMissions';

interface MissionListProps {
  solarFarmId: number;
  missionTaskForm: UseFormReturn;
  setBackButtonPopover: (backButtonPopover: MissionTaskPopovers) => void;
  setVisiblePopover: (visiblePopover: MissionTaskPopovers | null) => void;
  setIsSideBarOpen: (isSideBarOpen: boolean) => void;
}

interface Exlusions {
  DEVICES: DroneType[];
  INSPECTIONS: CombinedFlightModeType[];
}

const EXCLUSIONS: Exlusions = {
  DEVICES: [DroneType.MannedAirplane],
  INSPECTIONS: [FlightModeType.Airplane],
};

export const MissionListPopover = ({
  solarFarmId,
  missionTaskForm,
  setBackButtonPopover,
  setVisiblePopover,
  setIsSideBarOpen,
}: MissionListProps) => {
  const showSquareOrbit = useFeatureIsOn(GrowthbookFlags.SQUARE_ORBITAL);
  const { setValue, getValues } = missionTaskForm;
  const setSearchParams = useSearchParams()[1];
  const searchQueryParam =
    useUrlSearchParams<string>(MISSION_LIST_QURERY_PARAMS.search, 'string') ||
    '';
  const flightModeQueryParams =
    useUrlSearchParams<FlightModeType>(
      MISSION_LIST_QURERY_PARAMS.flightMode,
      'string',
    ) || '';
  const droneQueryParams =
    useUrlSearchParams<DroneType>(MISSION_LIST_QURERY_PARAMS.drone, 'string') ||
    '';
  const offset =
    useUrlSearchParams<number>(MISSION_LIST_QURERY_PARAMS.offset, 'number') ||
    0;

  const [search, setSearch] = useState<string>(searchQueryParam);

  const preSelectedMissionId = getValues(
    MISSION_TASK_FORM_FIELDS.detailViewMissionId,
  );

  if (showSquareOrbit) {
    EXCLUSIONS.INSPECTIONS = [
      ...EXCLUSIONS.INSPECTIONS,
      TemporaryFlightModeType.SquareOrbital,
    ];
  }

  const { data, isLoading, isFetching } = useGetMissions(
    solarFarmId,
    searchQueryParam,
    offset,
    droneQueryParams as DroneType,
    flightModeQueryParams as FlightModeType,
  );

  const missions = data?.missions.filter(mission => {
    return (
      // optional filters
      (!droneQueryParams || mission.drone === droneQueryParams) &&
      (!flightModeQueryParams || mission.mode === flightModeQueryParams) &&
      // manual filters
      !EXCLUSIONS.DEVICES.includes(mission.drone) &&
      !EXCLUSIONS.INSPECTIONS.includes(mission.mode)
    );
  });

  const sortedMissionsWithSelectedMissionFirst = useMemo(() => {
    const selectedMissionId = missionTaskForm.getValues(
      MISSION_TASK_FORM_FIELDS.missionIds,
    )[0];
    const selectedMission = missions.find(
      mission => mission.id === selectedMissionId,
    );
    const otherMissions = missions.filter(
      mission => mission.id !== selectedMissionId,
    );
    return selectedMission
      ? [selectedMission, ...otherMissions]
      : otherMissions;
  }, [
    missions,
    missionTaskForm.getValues(MISSION_TASK_FORM_FIELDS.missionIds),
  ]);

  const setQueryParams = (param: MISSION_LIST_QURERY_PARAMS, query: string) => {
    setSearchParams({
      solar_farm_id: solarFarmId?.toString() || '',
      [MISSION_LIST_QURERY_PARAMS.search]: search || '',
      [MISSION_LIST_QURERY_PARAMS.flightMode]: flightModeQueryParams,
      [MISSION_LIST_QURERY_PARAMS.drone]: droneQueryParams,
      [MISSION_LIST_QURERY_PARAMS.offset]: String(offset) || '0',
      [param]: query,
    });
  };

  const handleInputChange = debounce((query: string): void => {
    setQueryParams(MISSION_LIST_QURERY_PARAMS.search, query);
    setQueryParams(MISSION_LIST_QURERY_PARAMS.offset, '0');
  }, 750);

  useEffect(() => {
    handleInputChange(search);
    return () => {
      handleInputChange.cancel();
    };
  }, [search]);

  const handleSelectMissionClick = () => {
    setValue(MISSION_TASK_FORM_FIELDS.missionIds, [preSelectedMissionId], {
      shouldValidate: true,
      shouldDirty: true,
    });
    setIsSideBarOpen(true);
    setVisiblePopover(null);
  };

  return (
    <>
      <Row align="center" justify="space-between">
        <Text variant="h4">Mission Library</Text>
        <SidebarCloseButton
          onClick={() => {
            setIsSideBarOpen(true);
            setVisiblePopover(null);
          }}
          variant="tertiary"
          size="small"
          icon="Xmark"
          aria-label="Close Mission List Popover"
        />
      </Row>

      <FullWidthRow justify="space-between">
        <StyledSearchBarRow align="center" noWrap gutter="medium">
          <StyledSearchBarWithIcon
            inputSize="small"
            inputStyle="grey-box"
            placeholder="Search"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          {search && (
            <SearchBarIconButtonAdornment
              aria-label="Clear search bar"
              icon={'SquareXmark'}
              size="small"
              variant="tertiary"
              onClick={() => {
                setSearch('');
              }}
              onMouseDown={e => {
                e.preventDefault();
              }}
            />
          )}
        </StyledSearchBarRow>
        <Tooltip tip="Clear All Filters">
          <IconButton
            aria-label="Clear Filters"
            icon="Eraser"
            variant="tertiary"
            style={{ marginTop: '30px' }}
            onClick={() => {
              setSearchParams({
                solar_farm_id: solarFarmId?.toString() || '',
                search: '',
              });
              setSearch('');
            }}
          />
        </Tooltip>
      </FullWidthRow>

      <FullWidthRow justify="space-between" style={{ marginTop: '25px' }}>
        <DropdownMenu
          menuContentProps={{
            align: 'start',
            sideOffset: 0,
            side: 'bottom',
            style: {
              maxHeight: '70vh',
              width: '175px',
              minWidth: '100px',
              marginTop: 0,
            },
          }}
          items={generateDroneOptions(EXCLUSIONS.DEVICES, setQueryParams)}
          modal={false}
        >
          <DropdownButton
            size="small"
            variant="secondary"
            style={{ width: '47.5%' }}
          >
            {droneQueryParams
              ? DRONE_TYPE_TO_DISPLAY_NAME[droneQueryParams]
              : 'Drone Model'}
          </DropdownButton>
        </DropdownMenu>
        <DropdownMenu
          menuContentProps={{
            align: 'start',
            sideOffset: 0,
            side: 'bottom',
            style: {
              maxHeight: '70vh',
              width: '172px',
              minWidth: '120px',
              marginTop: 0,
            },
          }}
          items={generateFlightModeOptions(
            EXCLUSIONS.INSPECTIONS,
            setQueryParams,
          )}
          modal={false}
        >
          <DropdownButton
            size="small"
            variant="secondary"
            style={{ width: '47%' }}
          >
            {flightModeQueryParams
              ? FLIGHT_MODE_TO_DISPLAY_NAME[flightModeQueryParams]
              : 'Inspection Type'}
          </DropdownButton>
        </DropdownMenu>
      </FullWidthRow>

      <CardListContainer>
        {sortedMissionsWithSelectedMissionFirst.length > 0 ? (
          sortedMissionsWithSelectedMissionFirst.map(mission => {
            return (
              <MissionCard
                data-testid="mission-card"
                key={mission.id}
                mission={mission}
                missionTaskForm={missionTaskForm}
                backButtonPopover="missionList"
                isSelected={
                  preSelectedMissionId
                    ? preSelectedMissionId === mission.id
                    : missionTaskForm.getValues(
                        MISSION_TASK_FORM_FIELDS.missionIds,
                      )[0] === mission.id
                }
                setBackButtonPopover={setBackButtonPopover}
                setVisiblePopover={setVisiblePopover}
                handleClick={() =>
                  setValue(
                    MISSION_TASK_FORM_FIELDS.detailViewMissionId,
                    mission.id,
                  )
                }
              />
            );
          })
        ) : isLoading || isFetching ? (
          <Stack align="center" style={{ width: '100%' }}>
            <ThreeCircles
              height="40"
              width="40"
              color={theme.colors.primary_400}
              visible={true}
              ariaLabel="loading spinner"
            />
          </Stack>
        ) : (
          <Text
            variant="paragraph_medium"
            align="center"
            style={{ width: '100%' }}
          >
            No Missions Found
          </Text>
        )}

        {data?.total > 20 &&
          offset < data?.total &&
          !isLoading &&
          !isFetching && (
            <Button
              variant="secondary"
              style={{ marginTop: '5px' }}
              onClick={() => {
                setQueryParams(
                  MISSION_LIST_QURERY_PARAMS.offset,
                  String(offset + GET_MISSIONS_PAGINATION_LIMIT),
                );
              }}
            >
              Load More
            </Button>
          )}
      </CardListContainer>
      <SidebarBottomButtonContainer
        justify="space-around"
        style={{ marginBotom: '30px' }}
      >
        <Button
          style={{ width: '100%' }}
          onClick={handleSelectMissionClick}
          disabled={!preSelectedMissionId}
        >
          Select Mission
        </Button>
      </SidebarBottomButtonContainer>
    </>
  );
};
