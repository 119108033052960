import {
  AircraftChargeState,
  DockCoverState,
  AircraftOnlineState,
  AircraftState,
} from '../../shared/types/devices.d';

interface DeviceDisplayState {
  description: string;
  label: string;
  pillColor: string;
  isFlashing: boolean;
}

interface DeviceDisplayStateMap {
  [key: string]: DeviceDisplayState;
}

export const AIRCRAFT_CHARGE_STATE_TO_DISPLAY: DeviceDisplayStateMap = {
  [AircraftChargeState.Standby]: {
    description: 'The aircraft is idle.',
    label: 'Idle',
    pillColor: 'gray',
    isFlashing: false,
  },
  [AircraftChargeState.Charging]: {
    description: 'The aircraft is currently charging.',
    label: 'Charging',
    pillColor: 'green',
    isFlashing: false,
  },
  [AircraftChargeState.Unknown]: {
    description: 'The charge state is unknown.',
    label: 'Unknown',
    pillColor: 'red',
    isFlashing: false,
  },
};

export const DOCK_COVER_STATE_TO_DISPLAY: DeviceDisplayStateMap = {
  [DockCoverState.Closed]: {
    description: 'The dock cover is closed.',
    label: 'Closed',
    pillColor: 'red',
    isFlashing: false,
  },
  [DockCoverState.Open]: {
    description: 'The dock cover is open.',
    label: 'Open',
    pillColor: 'green',
    isFlashing: false,
  },
  [DockCoverState.HalfOpen]: {
    description: 'The dock cover is half open.',
    label: 'Half Open',
    pillColor: 'yellow',
    isFlashing: true,
  },
  [DockCoverState.Abnormal]: {
    description: 'The dock cover is in an abnormal state.',
    label: 'Abnormal',
    pillColor: 'red',
    isFlashing: false,
  },
  [DockCoverState.Unknown]: {
    description: 'The dock cover is in an abnormal state.',
    label: 'Abnormal',
    pillColor: 'red',
    isFlashing: false,
  },
  [DockCoverState.EStop]: {
    description: 'The emergency stop is active',
    label: 'EStop Active',
    pillColor: 'red',
    isFlashing: true,
  },
};

export const AIRCRAFT_ONLINE_STATE_TO_DISPLAY: DeviceDisplayStateMap = {
  [AircraftOnlineState.Off]: {
    description: 'The aircraft is offline.',
    label: 'Offline',
    pillColor: 'red',
    isFlashing: false,
  },
  [AircraftOnlineState.On]: {
    description: 'The aircraft is online.',
    label: 'Online',
    pillColor: 'green',
    isFlashing: false,
  },
};

export const AIRCRAFT_STATE_TO_DISPLAY: DeviceDisplayStateMap = {
  [AircraftState.Standby]: {
    description: 'The aircraft is in standby mode.',
    label: 'Standby',
    pillColor: 'gray',
    isFlashing: false,
  },
  [AircraftState.TakeoffPreparation]: {
    description: 'The aircraft is preparing for takeoff.',
    label: 'Takeoff Preparation',
    pillColor: 'gray',
    isFlashing: false,
  },
  [AircraftState.Manual]: {
    description: 'The aircraft is in manual mode.',
    label: 'Manual Flight',
    pillColor: 'purple',
    isFlashing: false,
  },
  [AircraftState.Takeoff]: {
    description: 'The aircraft is auto taking off.',
    label: 'Takeoff',
    pillColor: 'orange',
    isFlashing: true,
  },
  [AircraftState.Operating]: {
    description: 'The aircraft is operating.',
    label: 'Operating',
    pillColor: 'blue',
    isFlashing: false,
  },
  [AircraftState.AutoReturnHome]: {
    description: 'The aircraft is returning home.',
    label: 'Returning Home',
    pillColor: 'blue',
    isFlashing: false,
  },
  [AircraftState.Landing]: {
    description: 'The aircraft is auto landing.',
    label: 'Auto Landing',
    pillColor: 'orange',
    isFlashing: true,
  },
  [AircraftState.ForcedLanding]: {
    description: 'The aircraft is forced landing.',
    label: 'Forced Landing',
    pillColor: 'red',
    isFlashing: true,
  },
  [AircraftState.Upgrading]: {
    description: 'The aircraft is upgrading.',
    label: 'Upgrading',
    pillColor: 'gray',
    isFlashing: false,
  },

  [AircraftState.NotConnected]: {
    description: 'The aircraft is not connected.',
    label: 'Not Connected',
    pillColor: 'red',
    isFlashing: false,
  },
  [AircraftState.AirborneRTKFixingMode]: {
    description: 'The aircraft is in airborne RTK fixing mode.',
    label: 'Airborne RTK Fixing Mode',
    pillColor: 'green',
    isFlashing: false,
  },
  [AircraftState.DockAddressSelecting]: {
    description: 'The dock is selecting an address.',
    label: 'Dock Address Selecting',
    pillColor: 'green',
    isFlashing: false,
  },
  [AircraftState.Unknown]: {
    description: 'The aircraft is live.',
    label: 'Live',
    pillColor: 'red',
    isFlashing: false,
  },
};

export const UNAVAILABLE_VALUE = 'N/A';
export const UNKOWN_BATTERY_PERCENT = '-';
export const UNKNOWN_VALUE = 'unknown';
